import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  Textarea,
  useColorMode,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { InputControl } from "formik-chakra-ui";
import { checkColorMode, checkColorModeHover } from "../../global/changeColor";
import * as Yup from "yup";
const Schema = Yup.object().shape({
  title: Yup.string().required("Title is a required field"),
  body: Yup.string().required("Body is a required field"),
});
const InviteModal = ({ feildName, name, onAskMoreChange }) => {
  const { colorMode } = useColorMode();
  let [isOpen, setIsOpen] = useState(false);
  let [isChecked, setIsChecked] = useState(false);
  let [initValue] = useState({
    body: "",
    id: null,
    notes: "",
    patient_id: null,
    scanName: "",
    scanType: "",
    scan_type: feildName,
    title: "",
  });
  const onOpen = () => {
    return setIsOpen(true);
  };
  const onclose = () => {
    return setIsOpen(false);
  };
  const onFormSubmit = (values) => {
    const data = {
      ...values,
      notes: values.body,
      scanName: values.title,
      scanType: values.scan_type,
    };
    setIsOpen(false);
    setIsChecked(true);
    onAskMoreChange(data);
  };
  return (
    <div className="col-md-3 col-12 mb-3">
      <Button fontSize={"sm"} w={"100%"} onClick={onOpen}>
        {isChecked ? (
          <span
            className="me-2 text-success fw-bold"
            style={{ fontSize: "16px" }}
          >
            <i className="fas fa-check"></i>
          </span>
        ) : null}

        {name}
      </Button>
      <Modal isOpen={isOpen} onClose={onclose} size="3xl" isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <div className="d-flex justify-content-center mt-5 flex-wrap">
              <Text
                className=" rounded-circle d-flex justify-content-center align-items-center text-white"
                style={{
                  width: "90px",
                  height: "90px",
                  fontSize: "50px",
                }}
                bg={checkColorMode(colorMode)}
              >
                <i className="fas fa-plus"></i>
              </Text>
            </div>
            <Text className="w-100 text-center my-2 fw-bold">
              Request {name}
            </Text>
            <div className="d-flex justify-content-center mt-5 flex-wrap">
              <Formik
                initialValues={initValue}
                enableReinitialize
                validationSchema={Schema}
                onSubmit={onFormSubmit}
              >
                {({ values, setFieldValue, handleBlur, handleChange }) => (
                  <Form className="w-75">
                    <div className=" my-4  helperText">
                      <InputControl
                        size={"lg"}
                        className="w-100"
                        name="title"
                        inputProps={{
                          placeholder: `Briefly tell us about your ${name}`,
                        }}
                      />
                    </div>
                    <div className=" my-2  helperText">
                      <Textarea
                        className="mt-3 rounded"
                        value={values.body}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        name="body"
                        placeholder="Describe in as much detail as you can."
                        size="sm"
                      />
                      <ErrorMessage
                        component="div"
                        name="body"
                        style={{ color: "#d42c51", fontSize: "14px" }}
                        className="mt-1"
                      />
                    </div>
                    <div className="my-3 d-flex justify-content-center">
                      <Button
                        w="35%"
                        type="submit"
                        color="#fff"
                        bg={checkColorMode(colorMode)}
                        _hover={{ bg: checkColorModeHover(colorMode) }}
                        size="lg"
                      >
                        Request
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default InviteModal;
