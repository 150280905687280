import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NotFound from "../../../../../../NotFound/NotFound";
import ListingAllergies from "./Children/listingAllergies";
// import RequestAllergie from "./Children/requestAllergie";
import ViewOneAllergie from "./Children/viewOneAllergie";

const Allergies = ({ patient,queryParams }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <ListingAllergies patient={patient} queryParams={queryParams}/>}
      />
      {/* <Route
        exact
        path={`${path}/request-allergies`}
        component={RequestAllergie}
      /> */}
      <Route
        path={`${path}/view-allergies/:medicalId`}
        render={() => <ViewOneAllergie patient={patient} />}
      />
      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

export default Allergies;
