import React, { useEffect, useState } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getHospitalProfile } from "../../store/actions";
import MyInfo from "./My_info/myInfo";
import ProfileSideNav from "./sideNave/ProfileSideNav";
import { Text, Button, Box, useColorMode } from "@chakra-ui/react";
import NotFound from "../NotFound/NotFound";
import "./profile.scss";
import ChangePassword from "./Change-Password/changePassword";
import { checkBgColorMode, checkColorMode } from "../global/changeColor";

const Profile = ({ getHospitalProfile, HospitaleProfile }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [openProfileNav, setOpenProfileNav] = useState(false);

  const { path } = useRouteMatch();
  const { colorMode } = useColorMode();

  useEffect(() => {
    getHospitalProfile();
  }, [getHospitalProfile]);

  useEffect(() => {
    const { loading, error, data } = HospitaleProfile;
    setError(error);
    setLoading(loading);
    if (data) {
      setUser(data);
    }
  }, [HospitaleProfile]);

  const renderView = () => {
    return (
      <>
        <div className="container profile ">
          <div className="row position-relative">
            <div className="col-12 mb-3 d-flex align-items-center mt-5">
              <div className="d-xl-none d-lg-none d-xl-flex me-2">
                <Button
                  color={checkColorMode(colorMode)}
                  onClick={() => {
                    setOpenProfileNav(!openProfileNav);
                  }}
                >
                  <span>
                    <i className="fas fa-bars"></i>
                  </span>
                </Button>
              </div>
              <Text fontWeight="semibold" fontSize="3xl">
                My Account
              </Text>
            </div>
            <div className="col-12 row align-items-start m-0">
              <Box
                bg={checkBgColorMode(colorMode)}
                className="d-none d-lg-block col-3 rounded shadow-sm p-2 d-flex justify-content-center flex-wrap"
              >
                <ProfileSideNav />
              </Box>

              <div className="col-lg-9 mb-5">
                <Switch>
                  <Route
                    exact
                    path={`${path}`}
                    render={() => (
                      <MyInfo
                        loading={loading}
                        error={error}
                        hospitalProfile={user}
                      />
                    )}
                  />
                  <Route
                    path={`/profile/change_password`}
                    component={ChangePassword}
                  />
                  <Route path="*" exact={true} component={NotFound} />
                </Switch>
              </div>
            </div>
          </div>
          <Box
            className="position-absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg={"rgba(0,0,0,.2)"}
            display={openProfileNav ? "block" : "none"}
            zIndex={100}
            onClick={() => {
              setOpenProfileNav(!openProfileNav);
            }}
          ></Box>
        </div>
        <Box
          w="260px"
          zIndex={200}
          top={0}
          left={openProfileNav ? "0" : "-1100px"}
          transition=".3s ease-in-out"
          bg={checkBgColorMode(colorMode)}
          className={`position-absolute ${
            openProfileNav ? "" : "rounded"
          } shadow-sm p-2 d-flex justify-content-center flex-wrap `}
        >
          <ProfileSideNav
            fromSmallScreen={true}
            openProfileNav={openProfileNav}
            setOpenProfileNav={setOpenProfileNav}
          />
        </Box>
      </>
    );
  };

  return renderView();
};
const mapStateToProps = (state) => {
  return {
    HospitaleProfile: state.HospitaleProfile,
  };
};
export default connect(mapStateToProps, { getHospitalProfile })(Profile);
