import { Box, Circle, Text, useColorMode } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { style } from "../../../../global/style";
import { helperImageAndLinks } from "./LinksAndImages";
import { useParams } from "react-router-dom";
import {
  checkBgColorMode,
  checkColorMode,
} from "../../../../global/changeColor";

const Requests = ({ requestes }) => {
  const { colorMode } = useColorMode();
  const { id } = useParams();
  const renderData = () => {
    if (requestes.length) {
      return (
        <motion.div className="" initial="out" animate="in" exit="out">
          <div className="requests row m-0">
            <Box className="my-3 col-12">
              <Text
                fontSize="2xl"
                fontWeight="semibold"
                color={checkColorMode(colorMode)}
              >
                Requests
              </Text>
            </Box>
            {requestes.map((request, index) => (
              <Box
                as={Link}
                to={helperImageAndLinks(request, id).link}
                key={index}
                className="col-12 shadow-sm rounded p-3 row m-0 align-items-center my-2"
                bg={checkBgColorMode(colorMode)}
              >
                <Box className="col-md-4 d-flex  align-items-center">
                  <Circle size="35px" bg={checkColorMode(colorMode)}>
                    {helperImageAndLinks(request, id).img ? (
                      <img
                        className=" w-50 h-50"
                        src={helperImageAndLinks(request, id).img}
                        alt="c"
                      />
                    ) : (
                      <span style={{ color: "#fff" }}>
                        <i
                          className={helperImageAndLinks(request, id).className}
                        ></i>
                      </span>
                    )}
                  </Circle>

                  <Box className=" p-1 ms-1">
                    <Text
                      color={style.smallTextGray}
                      fontSize="14px"
                      fontWeight="600"
                      textTransform="capitalize"
                    >
                      {request.title}
                    </Text>
                    <Text fontSize="13px" fontWeight="700">
                      {request.body}
                    </Text>
                  </Box>
                </Box>
                {/* <Box className="col-md-4 row m-0">
                  <Box className="">
                    <Text
                      color={style.smallTextGray}
                      fontSize="14px"
                      fontWeight="600"
                      textTransform="capitalize"
                    >
                      Time
                    </Text>
                    <Text fontSize="13px" fontWeight="700">
                      {moment
                        .unix(request.created_at)
                        .format("DD/MM/YYYY hh:mm a")}
                    </Text>
                  </Box>
                </Box> */}
              </Box>
            ))}
          </div>
        </motion.div>
      );
    } else {
      return (
        <Box className="row my-3 ">
          <Text fontSize="2xl" fontWeight="semibold" color={style.primary}>
            Requests
          </Text>
          <Text className="mt-3" fontWeight="semibold" fontSize="2xl">
            There's no Requests
          </Text>
        </Box>
      );
    }
  };
  return renderData();
};

export default Requests;
