import React from "react";
import { Box, Text, useColorMode } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

import emptyConsultationsSvg from "../../../assets/svg-icons/empty-consultations.svg";
import { style } from "../style";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../changeColor";

const EmptyRequests = ({ headerText, bodyText, btnText, linkTo }) => {
  const { colorMode } = useColorMode();
  return (
    <div className="row m-0 justify-content-center mt-5">
      <Box
        bg={checkBgColorMode(colorMode)}
        className="col-md-8 col-12 p-3 pb-5 rounded shadow m-0"
      >
        <img
          className="mx-auto"
          width={250}
          src={emptyConsultationsSvg}
          alt="Empty Consultations"
        />

        <Text
          color={colorMode === "dark" ? "white" : "black"}
          fontWeight="bold"
          fontSize="xl"
          className="text-center mb-4"
        >
          {headerText}
        </Text>
        <Text
          color={style.smallTextGray}
          fontSize="sm"
          className="text-center mb-4"
        >
          {bodyText}
        </Text>
        <Box className="col-12 text-center">
          <Button
            className="chakra-button rounded"
            w="60%"
            size="lg"
            bg={checkColorMode(colorMode)}
            color={"#fff"}
            _hover={{ color: "#fff", bg: checkColorModeHover(colorMode) }}
            as={Link}
            to={linkTo}
          >
            {btnText}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default EmptyRequests;
