import React, { useEffect } from "react";

import Card from "./Card";
import HeaderSection from "./headerSection";
import { motion } from "framer-motion";
import {
  pageVariants,
  pageTransition,
} from "../../../../../../global/Animations/Animations";
import EmptyMedical from "./empetyMedical";
import Pagination from "../../../../../../Pagination/Pagination";
import { useLocation } from "react-router-dom";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const FirstView = ({
  loading,
  error,
  headerName,
  data,
  buttonLink,
  cardLink,
  filterData,
  setFilter,
  conditionImage,
  pages,
  nextPage,
  prevPage,
  specificPage,
  currentPage,
  totalResults,
  question,
}) => {
  let queryParams = useQuery().get("newRequest");

  useEffect(() => {
    renderView();
  }, []);
  const onInputChange = (e) => {
    let term = e.target.value;
    if (data && filterData) {
      if (term.trim().length > 0) {
        const filterD = data.filter((one) =>
          one.title
            ? one.title.toLowerCase().includes(term.trim().toLowerCase())
            : one.value.toLowerCase().includes(term.trim().toLowerCase())
        );
        setFilter(filterD);
      } else {
        setFilter(data);
      }
    } else {
      return null;
    }
  };

  const renderView = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return "Error";
    }
    if (data && data.length) {
      return (
        <>
          <HeaderSection
            headerName={headerName}
            onInputChange={onInputChange}
            buttonLink={buttonLink}
            question={question}
          />
          <motion.div>
            {filterData.map((item, i) => (
              <motion.div
                key={i}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                transition={{ duration: 0.6, delay: i * 0.1 }}
              >
                <Card item={item} url={`${cardLink}`} queryParams={queryParams}/>
              </motion.div>
            ))}
          </motion.div>
          {totalResults && totalResults > 15 ? (
            <Pagination
              pages={pages}
              nextPage={nextPage}
              prevPage={prevPage}
              specificPage={specificPage}
              currentPage={currentPage}
            />
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <EmptyMedical
          name={headerName}
          image={conditionImage}
          LinkTo={buttonLink}
        />
      );
    }
  };
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {renderView()}
    </motion.div>
  );
};

export default FirstView;
