import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { NavLink, useHistory } from "react-router-dom";
import { logIn } from "../../store/actions";
import { InputControl } from "formik-chakra-ui";
import PasswordField from "../global/passwordField";
import { Button, useToast, useColorMode, Box, Text } from "@chakra-ui/react";
import isAuthenticated from "../../utils/isAuthenticated";
import { style } from "../global/style";
import logo from "../../assets/logo.svg";
import logo_dark from "../../assets/logo_dark.svg";

import "./Login.scss";
import {
  checkColorMode,
  checkBgColorMode,
  checkColorModeHover,
} from "../global/changeColor";

const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6).required("Required"),
});

const Login = ({ logIn, user }) => {
  const toast = useToast();
  const history = useHistory();
  const { colorMode } = useColorMode();
  const [initValue] = useState({
    email: "",
    password: "",
  });

  const onFormSubmit = (value) => {
    logIn(value, history, toast);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      toast({
        title: "You're already logged in",
        status: "success",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });
      history.push("/");
    }
  }, [toast, history]);

  return (
    <div className="row justify-content-center m-0 my-5">
      <div className="col-lg-5 col-md-7 col-11">
        <Formik
          initialValues={initValue}
          enableReinitialize
          validationSchema={SignInSchema}
          onSubmit={onFormSubmit}
        >
          <div>
            <p className="fw-bold mb-2">Login</p>
            <Box
              bg={checkBgColorMode(colorMode)}
              className="rounded shadow-sm p-5"
            >
              <Form>
                <div className="header row justify-content-center mb-5">
                  <img
                    src={colorMode === "dark" ? logo_dark : logo}
                    alt="logo"
                    className="w-75"
                  />
                </div>

                <div className="input-group mt-2">
                  <InputControl
                    name="email"
                    label="E-mail"
                    inputProps={{
                      placeholder: "E-mail",
                      disabled: user.loading,
                    }}
                    size="lg"
                  />
                </div>

                <div className="input-group mt-2">
                  <PasswordField label="Password" />
                </div>

                <div className="buttonSubmit">
                  <Button
                    type="submit"
                    className="w-100 mt-3 fw-bold"
                    size="lg"
                    isLoading={user.loading}
                    bg={checkColorMode(colorMode)}
                    color="#fff"
                    _hover={{ bg: checkColorModeHover(colorMode) }}
                  >
                    Login
                  </Button>
                </div>

                <div className="text-center pt-4">
                  <Text
                    as={NavLink}
                    to="/forgetpassword"
                    className="fw-bold forget-password"
                    _hover={{ color: checkColorMode(colorMode) }}
                  >
                    Forgot password?
                  </Text>
                </div>
              </Form>
            </Box>
          </div>
        </Formik>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.login,
  };
};
export default connect(mapStateToProps, { logIn })(Login);
