import axios from "axios";
import { types } from "../types/types";
import { handeleError, handeleSuccess } from "./Toast/toast";

const { hospitalTypes } = types;
const API_URL = process.env.REACT_APP_API_URL;

export const getHospitalProfile = () => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_HOSPITAL_PROFILE_LOADING,
    });
    try {
      const response = await axios({
        method: "GET",
        url: `${API_URL}/hospital/profile`,
      });
      dispatch({
        type: hospitalTypes.GET_HOSPITAL_PROFILE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_HOSPITAL_PROFILE_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
export const updateHospitalProfile = (
  data,
  history,
  toast,
  goTO,
  setEdite,
  getConsultantProfile
) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.UPDATE_HOSPITAL_PROFILE_LOADING,
    });
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API_URL}/hospital/profile`,
        data: data,
      });
      if (res) {
        handeleSuccess("Your profile has been updated successfully", toast);
        if (goTO) history.push(goTO);
        if (setEdite) setEdite(false);
        getConsultantProfile();
        dispatch({
          type: hospitalTypes.UPDATE_HOSPITAL_PROFILE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: hospitalTypes.UPDATE_HOSPITAL_PROFILE_ERROR,
        payload: error.response,
      });
    }
  };
};

export const postHospitalProfilePicture = (data, toast) => {
  let formData = new FormData();
  if (data) {
    data.map((file) => formData.append("picture", file, file.name));
  }
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.PROFILE_PICTURE_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/hospital/profile-picture`,
        data: formData,
      });
      handeleSuccess(
        "Your Profile Picture has been updated sucsessfuly",
        toast
      );
      dispatch({
        type: hospitalTypes.PROFILE_PICTURE_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: hospitalTypes.PROFILE_PICTURE_RORR,
        payload: error.response,
      });
    }
  };
};

export const changePassword = (data, toast, history, logOut) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.CHANGE_PASSWORD_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/hospital/change-password`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your Password Updated sucsessfuly", toast);
        logOut();
        history.push("/login");
        dispatch({
          type: hospitalTypes.CHANGE_PASSWORD_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: hospitalTypes.CHANGE_PASSWORD_ERROR,
        payload: error.response,
      });
    }
  };
};

export const getNewHospitalRequests = (pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_NEW_HOSPITAL_REQUESTS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/hospital/patient-quotation-requests/new?page=${pageNumber}`,
      });
      dispatch({
        type: hospitalTypes.GET_NEW_HOSPITAL_REQUESTS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_NEW_HOSPITAL_REQUESTS_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getHospitalRequests = () => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_HOSPITAL_REQUESTS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/hospital/patient-quotation-requests`,
      });

      dispatch({
        type: hospitalTypes.GET_HOSPITAL_REQUESTS_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_HOSPITAL_REQUESTS_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

//generateInformationShareToken
export const generateInformationShareToken = (patientId, toast) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GENERATE_INFORMATION_SHARE_TOKEN_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/hospital/patient/${patientId}/generate-information-share-token`,
      });
      if (res) {
        handeleSuccess("The Link Copied", toast);
        dispatch({
          type: hospitalTypes.GENERATE_INFORMATION_SHARE_TOKEN_SUCSES,
          payload: res.data.informationShareToken,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: hospitalTypes.GENERATE_INFORMATION_SHARE_TOKEN_ERROR,
        payload: error.response,
      });
    }
  };
};

//invitePatient
export const invitePatient = (data, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.INVITE_PATIENT_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/hospital/invites/patient`,
        data: data,
      });
      if (res) {
        handeleSuccess("Done", toast);
        dispatch({
          type: hospitalTypes.INVITE_PATIENT_SUCCESS,
          payload: res.data,
        });
        history.goBack();
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: hospitalTypes.INVITE_PATIENT_ERROR,
        payload: error.response,
      });
    }
  };
};

export const getPatientsInvitationList = (pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_INVITATION_LIST_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/hospital/invites/patient?page=${pageNumber}`,
      });

      dispatch({
        type: hospitalTypes.GET_INVITATION_LIST_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_INVITATION_LIST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getSinglePatientsInvitation = (patientId) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_SINGLE_INVITATION_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/hospital/invites/patient/${patientId}`,
      });

      dispatch({
        type: hospitalTypes.GET_SINGLE_INVITATION_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_SINGLE_INVITATION_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getMedicalInformationFileLink = (patientId, toast) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_MEDICAL_INFORMATION_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/hospital/patient/${patientId}/medical-information-file`,
      });
      handeleSuccess(
        "We will send the file to your email address once it is available to download",
        toast
      );
      dispatch({
        type: hospitalTypes.GET_MEDICAL_INFORMATION_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_MEDICAL_INFORMATION_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getSingleNewRequrst = (Id) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_SINGLE_NEW_REQUEST_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/hospital/patient-quotation-requests/${Id}`,
      });

      dispatch({
        type: hospitalTypes.GET_SINGLE_NEW_REQUEST_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_SINGLE_NEW_REQUEST_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const rejectQuotationRequest = (requestId, toast, history, goTo) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.REJECT_REQUEST_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/hospital/patient-quotation-requests/${requestId}/reject`,
      });
      if (res) {
        handeleSuccess("Done", toast);
        history.push("/");
        dispatch({
          type: hospitalTypes.REJECT_REQUEST_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: hospitalTypes.REJECT_REQUEST_ERROR,
        payload: error.response,
      });
    }
  };
};

export const sendQuotation = (requestId, data, toast, history, goTo) => {
  // let formData = new FormData();
  // formData.append("note", data.note);
  // if (data.files) {
  //   data.files.map((file) => formData.append("file", file, file.name));
  // }
  // formData.append("title", data.title);

  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.SEND_QUOTATION_REQUEST_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/hospital/patient-quotation-requests/${requestId}/accept`,
        data: data,
      });
      if (res) {
        handeleSuccess("Done", toast);
        dispatch({
          type: hospitalTypes.SEND_QUOTATION_REQUEST_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: hospitalTypes.SEND_QUOTATION_REQUEST_ERROR,
        payload: error.response,
      });
    }
  };
};

export const getSentQuotationRequests = (pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_SENT_QUOTATION_REQUESTS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/hospital/patient-quotation-requests?status=admin-contacted,canceled,new&&page=${pageNumber}`,
      });

      dispatch({
        type: hospitalTypes.GET_SENT_QUOTATION_REQUESTS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_SENT_QUOTATION_REQUESTS_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getBookedQuotationRequests = (pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_BOOKED_QUOTATION_REQUESTS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/hospital/patient-quotation-requests?status=booked,canceled&&page=${pageNumber}`,
      });

      dispatch({
        type: hospitalTypes.GET_BOOKED_QUOTATION_REQUESTS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_BOOKED_QUOTATION_REQUESTS_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getAnyRequestWithQuotation = (id) => {
  return async (dispatch) => {
    dispatch({
      type: hospitalTypes.GET_ANY_QUOTATION_REQUESTS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/hospital/patient-quotation-requests/${id}`,
      });

      dispatch({
        type: hospitalTypes.GET_ANY_QUOTATION_REQUESTS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: hospitalTypes.GET_ANY_QUOTATION_REQUESTS_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
