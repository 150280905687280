import React from "react";
import {
  Avatar,
  Box,
  Circle,
  Text,
  Divider,
  useColorMode,
} from "@chakra-ui/react";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { style } from "../style";
import { checkBgColorMode, checkColorMode } from "../changeColor";
import { check_status_string } from "../statusCheck";
import moment from "moment";
import { GetSpecialityIcon } from "../GetSpecialityIcon.js";

const Card = ({
  item,
  index,
  ConsultationType,
  linkTo,
  hospitalRequests,
  newRequest,
  withQuotation,
}) => {
  const { colorMode } = useColorMode();
  return (
    <>
      <Box
        as={motion.div}
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{ duration: 0.8, delay: index * 0.1 }}
        key={item.id}
        className="row m-0 mb-2  shadow-sm p-3 pe-0 text-dark rounded align-items-center position-relative"
        bg={checkBgColorMode(colorMode)}
      >
        <Box
          as={Link}
          key={index}
          to={`${linkTo}/${item.id} ${newRequest ? "?newRequest=true" : ""}`}
          className="row col-12 align-items-center  "
          _hover={{ color: colorMode === "dark" ? "white" : "black" }}
        >
          <div className={hospitalRequests ? "col-lg-4 my-2" : "col-lg-3 my-2"}>
            <Text
              fontSize="md"
              fontWeight={700}
              color={colorMode === "dark" ? "white" : "black"}
            >
              {item.patientConsultationRequest?.symptoms ||
                item.invitationText ||
                item.note}
            </Text>
            <Box color="gray.400" fontSize="12px">
              {withQuotation ? (
                <div>
                  {item.patientConsultationRequest?.createdAt
                    ? moment(
                        item.patientConsultationRequest.createdAt * 1000
                      ).format("DD/MM/YYYY - hh:mm A")
                    : ""}
                </div>
              ) : (
                <div>
                  {item.updatedAt
                    ? moment(item.updatedAt * 1000).format(
                        "MM/DD/YYYY - hh:mm A"
                      )
                    : moment(item.createdAt * 1000).format(
                        "MM/DD/YYYY - hh:mm A"
                      )}
                </div>
              )}
            </Box>
          </div>
          {!hospitalRequests ? (
            <div className="col-lg-3 d-flex align-items-center m-0 my-2">
              <div className="me-2">
                <Circle size="40px" bg={checkColorMode(colorMode)}>
                  {console.log(item)}
                  {item.patientConsultationRequest?.consultationTypeId ? (
                    <img
                      className="image-fluid"
                      src={GetSpecialityIcon(
                        Number(
                          item.patientConsultationRequest?.consultationTypeId
                        )
                      )}
                      alt="chest"
                    />
                  ) : (
                    ""
                  )}
                </Circle>
              </div>

              <Box className="p-0">
                <Text color="gray.400" fontSize="14px" fontWeight="600">
                  Speciality
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight="700"
                  color={colorMode === "dark" ? "white" : "black"}
                >
                  {item.patientConsultationRequest && ConsultationType.length
                    ? ConsultationType.find(
                        (element) =>
                          element.id ===
                          item.patientConsultationRequest.consultationTypeId
                      ).name
                    : " "}
                </Text>
              </Box>
            </div>
          ) : (
            ""
          )}

          {item.patient && (item.patient.fullName || item.patient.firstName) ? (
            <div className="col-lg-4 d-flex align-items-center m-0 my-2">
              <div className="me-2">
                <Avatar
                  bg={checkColorMode(colorMode)}
                  color="white"
                  size="md"
                  src={item.patient.picture}
                  name={item.patient.fullName || item.patient.firstName}
                />
              </div>
              <Box className="p-1">
                <Text color="gray.400" fontSize="14px" fontWeight="600">
                  Patient
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight="700"
                  color={colorMode === "dark" ? "white" : "black"}
                >
                  {item.patient.fullName || item.patient.firstName}
                </Text>
              </Box>
            </div>
          ) : item.name ? (
            <div className="col-lg-4 d-flex align-items-center m-0 my-2">
              <div className="me-2">
                <Avatar
                  bg={checkColorMode(colorMode)}
                  size="md"
                  color="#fff"
                  src={item.name}
                  name={item.name}
                />
              </div>
              <Box className="p-1">
                <Text color="gray.400" fontSize="14px" fontWeight="600">
                  Patient
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight="700"
                  color={colorMode === "dark" ? "white" : "black"}
                >
                  {item.name}
                </Text>
              </Box>
            </div>
          ) : null}

          {withQuotation && item.hospitalReservationQuotation?.length ? (
            ""
          ) : (
            <div className="col-lg-2 my-2 ms-auto">
              <div
                className={`${
                  check_status_string(item.status).className
                } text-center rounded`}
              >
                {item.status.replace(/-/g, " ")}
              </div>
            </div>
          )}

          {withQuotation
            ? item.hospitalReservationQuotation?.map((offer) => (
                <Box key={offer.id}>
                  <Box className="my-4">
                    <Divider />
                  </Box>
                  <Box className="row align-items-center justify-content-between m-0 my-2">
                    <Box
                      bg={style.smallTextGrayLight}
                      className="col-md-3 d-flex py-2 rounded justify-content-between my-2"
                    >
                      <Box className="d-flex">
                        <Text
                          className="me-2"
                          color={checkColorMode(colorMode)}
                        >
                          <i className="fas fa-file-pdf"></i>
                        </Text>
                        <Text
                          fontSize="sm"
                          color={colorMode === "dark" ? "white" : "black"}
                        >
                          Quotation.pdf
                        </Text>
                      </Box>
                      <Box className="justify-self-end">
                        <Text className="" color={checkColorMode(colorMode)}>
                          <i className="fas fa-eye"></i>{" "}
                        </Text>
                      </Box>
                    </Box>

                    <div className="col-md-2 my-2">
                      <Text
                        className=""
                        color={style.smallTextGray}
                        fontSize="sm"
                      >
                        Sent on
                      </Text>
                      <Text
                        className="fw-bold"
                        fontSize="xs"
                        color={colorMode === "dark" ? "white" : "black"}
                      >
                        {moment(offer.createdAt * 1000).format(
                          "MM/DD/YYYY - hh:mm A"
                        )}
                      </Text>
                      <Text></Text>
                    </div>

                    <div className="col-md-2 my-2">
                      <Text
                        className=""
                        color={style.smallTextGray}
                        fontSize="sm"
                      >
                        The price
                      </Text>
                      <Text
                        className="fw-bold"
                        fontSize="xs"
                        color={colorMode === "dark" ? "white" : "black"}
                      >
                        {offer.price}
                      </Text>
                      <Text></Text>
                    </div>

                    <div className="col-md-2 my-2">
                      <Text
                        className=""
                        color={style.smallTextGray}
                        fontSize="sm"
                      >
                        Earliest Check In
                      </Text>
                      <Text
                        className="fw-bold"
                        fontSize="xs"
                        color={colorMode === "dark" ? "white" : "black"}
                      >
                        {moment(offer.earliestCheckInTime * 1000).format(
                          "MM/DD/YYYY - hh:mm A"
                        )}
                      </Text>
                      <Text></Text>
                    </div>

                    <div className="col-md-2 my-2">
                      <Text
                        className=""
                        color={style.smallTextGray}
                        fontSize="sm"
                      >
                        Duration of stay
                      </Text>
                      <Text
                        className="fw-bold"
                        fontSize="xs"
                        color={colorMode === "dark" ? "white" : "black"}
                      >
                        {`${offer.durationInDays} Days`}
                      </Text>
                      <Text></Text>
                    </div>
                  </Box>
                  <div
                    className="col-md-2 col-4 position-absolute "
                    style={{
                      top: "20px",
                      right: "20px",
                    }}
                  >
                    <div
                      className={`${
                        check_status_string(offer.status).className
                      } text-center rounded`}
                    >
                      {offer.status.replace(/-/g, " ")}
                    </div>
                  </div>
                </Box>
              ))
            : ""}
        </Box>
      </Box>
    </>
  );
};

export default Card;
