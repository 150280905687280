import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getConsultationsTyeps,
  getAnyRequestWithQuotation,
} from "../../store/actions";
import { useParams } from "react-router-dom";
import RequestOverView from "../RequestOverView/RequestOverView";
import AnimatedPages from "../Animation/AnimatedPages";
const ViewQuotation = ({
  getAnyRequestWithQuotation,
  getConsultationsTyeps,
  consultationTypes,
  RequestWithQuotation,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [singleInvitation, setSingleData] = useState();
  const [requests, setRequests] = useState([]);

  const [ConsultationType, setConsultationType] = useState([]);

  const [medicalReport, setMedicalReport] = useState(null);
  const [patient, setPatient] = useState(null);
  const [HospitalReservationQuotation, setHospitalReservationQuotation] =
    useState([]);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      getConsultationsTyeps();
      getAnyRequestWithQuotation(id);
    }
  }, [id]);
  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setConsultationType(consultationTypes.data);
    }
  }, [consultationTypes]);

  useEffect(() => {
    if (RequestWithQuotation) {
      const { loading, error, data } = RequestWithQuotation;
      setLoading(loading);
      setError(error);
      if (data) {
        setSingleData(data);
        const { patient, requests, hospitalReservationQuotation } = data;
        setPatient(patient);
        setRequests(requests);
        if (data.patientConsultationRequest) {
          setMedicalReport(data.patientConsultationRequest.medicalReports);
        }
        if (
          hospitalReservationQuotation &&
          hospitalReservationQuotation.length
        ) {
          setHospitalReservationQuotation(hospitalReservationQuotation);
        }
      }
    }
  }, [RequestWithQuotation]);

  const renderView = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return "Error";
    }
    if (singleInvitation) {
      return (
        <RequestOverView
          requestes={requests}
          medicalReport={medicalReport}
          singleInvitation={singleInvitation}
          ConsultationType={ConsultationType}
          patient={patient}
          withQuotation={true}
          HospitalReservationQuotation={HospitalReservationQuotation}
        />
      );
    } else {
      return "";
    }
  };
  return <AnimatedPages>{renderView()}</AnimatedPages>;
};

const mapStateToProps = (state) => {
  return {
    RequestWithQuotation: state.AnyRequestWithQuotation,
    consultationTypes: state.consultationTypes,
  };
};
export default connect(mapStateToProps, {
  getConsultationsTyeps,
  getAnyRequestWithQuotation,
})(ViewQuotation);
