import React from "react";
import allergy from "../../../../../assets/medical-record/allergy.png";
import condition from "../../../../../assets/medical-record/condition.png";
import ctScan from "../../../../../assets/medical-record/ctScan.png";
import Egc from "../../../../../assets/medical-record/Egc.png";
import labResult from "../../../../../assets/medical-record/lab-result.png";
import medicalReport from "../../../../../assets/medical-record/medical-report.png";
import Xray from "../../../../../assets/medical-record/Xray.png";
import bloodPressure from "../../../../../assets/Readings/bloodPressure.png";
import bloodSugar from "../../../../../assets/Readings/bloodSugar.png";
import height from "../../../../../assets/Readings/height.png";
import temperature from "../../../../../assets/Readings/temperature.png";
import weight from "../../../../../assets/Readings/weight.png";
import Mri from "../../../../../assets/medical-record/ctScan.png";

export const helperImageAndLinks = (item, id) => {
  const { scan_type } = item;
  switch (scan_type) {
    case "radiology-xray":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/x-ray`,
        img: Xray,
      };

    case "radiology-mri":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/mri-ct scan`,
        img: Mri,
      };

    case "radiology-ct":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/mri-ct scan`,
        img: Mri,
      };

    case "allergy":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/allergies`,
        img: allergy,
      };

    case "ecg":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/ecg`,
        img: Egc,
      };

    case "lab_result":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/lab-result`,
        img: labResult,
      };

    case "condition":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/conditions`,
        img: condition,
      };

    case "new-radiology":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/radiology`,
        img: ctScan,
      };

    case "medical_report":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/medical-report`,
        img: medicalReport,
      };

    case "consultant-question":
      return {
        link: `/hospital_requests/invited-patient/${id}/medical-record/question`,
        img: null,
        className: "far fa-question-circle",
      };

    case "reading-height":
      return {
        link: `/hospital_requests/invited-patient/${id}/readings`,
        img: height,
      };

    case "reading-weight":
      return {
        link: `/hospital_requests/invited-patient/${id}/readings`,
        img: weight,
      };

    case "reading-blood-pressure":
      return {
        link: `/hospital_requests/invited-patient/${id}/readings`,
        img: bloodPressure,
      };

    case "reading-blood-sugar":
      return {
        link: `/hospital_requests/invited-patient/${id}/readings`,
        img: bloodSugar,
      };

    case "reading-heart-rate":
      return {
        link: `/hospital_requests/invited-patient/${id}/readings`,
        img: null,
        className: "fas fa-heartbeat",
      };

    case "reading-temperature":
      return {
        link: `/hospital_requests/invited-patient/${id}/readings`,
        img: temperature,
      };

    default:
      return {
        link: `#`,
        img: "",
      };
  }
};
