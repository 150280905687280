import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getSentQuotationRequests,
  getConsultationsTyeps,
} from "../../store/actions";
import AnimatedPages from "../Animation/AnimatedPages";
import {
  Avatar,
  Box,
  Button,
  Circle,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { style } from "../global/style";
import { check_status_string, filterStatus } from "../global/statusCheck";
import moment from "moment";
import chest from "../../assets/svg-icons/chest.svg";
import Pagination from "../Pagination/Pagination";
import EmptyRequests from "../global/components/EmptyRequests";
import Card from "../global/components/Card";
import { useLocation, useRouteMatch, useHistory, Link } from "react-router-dom";
import { checkBgColorMode, checkColorMode } from "../global/changeColor";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const SentQuotationsListing = ({
  getSentQuotationRequests,
  SentQuotation,
  getConsultationsTyeps,
  consultationTypes,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [sentQuotation, setSentQuotation] = useState([]);
  const [filterSentQuotation, setFilterSentQuotation] = useState([]);
  const [ConsultationType, setConsultationType] = useState([]);

  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  //
  let { url } = useRouteMatch();
  let query = useQuery().get("page");
  const { colorMode } = useColorMode();
  const history = useHistory();
  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };
  useEffect(() => {
    getConsultationsTyeps();
    getSentQuotationRequests(query);
  }, [getConsultationsTyeps, getSentQuotationRequests, query]);

  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setConsultationType(consultationTypes.data);
    }
  }, [consultationTypes]);

  useEffect(() => {
    const { loading, error, data } = SentQuotation;
    setLoading(loading);
    setError(error);
    if (data && data.data) {
      const list = data.data;
      setSentQuotation(list);
      setFilterSentQuotation(list);
      if (data.meta) {
        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);
      } else {
        setTotalResults(0);
      }
    }
  }, [SentQuotation]);
  const renderView = () => {
    if (loading) {
      return "";
    }
    if (error) {
      return "error";
    }
    if (sentQuotation.length) {
      return (
        <AnimatedPages>
          <div className="container my-5">
            <div className="row">
              <div className="h4  mb-4 fw-bold d-flex flex-wrap align-items-center justify-content-between">
                <Text fontSize="2xl" className="" fontWeight="700">
                  Sent Quotations
                </Text>

                <div className="d-flex flex-wrap align-items-center">
                  <div className="me-3 input-search my-2">
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={
                          <Text
                            color={checkColorMode(colorMode)}
                            className="icon-search"
                          >
                            <i className="fas fa-search"></i>
                          </Text>
                        }
                      />
                      <Input
                        w="300px"
                        placeholder="Search"
                        size="lg"
                        className="ps-5"
                        bg={checkBgColorMode(colorMode)}
                        onChange={(e) => {
                          let term = e.target.value;
                          if (term.trim().length > 0) {
                            const consultationExist = sentQuotation.filter(
                              (one) => one.patientConsultationRequest !== null
                            );
                            const filterD = consultationExist.filter((one) =>
                              one.patientConsultationRequest.symptoms
                                .toLowerCase()
                                .includes(term.trim().toLowerCase())
                            );
                            setFilterSentQuotation(filterD);
                          } else {
                            setFilterSentQuotation(sentQuotation);
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="ms-2 my-2">
                    <Menu>
                      <MenuButton
                        as={Button}
                        color={checkColorMode(colorMode)}
                        bg={
                          colorMode === "dark"
                            ? "secondaryOpacity"
                            : "primaryOpacity"
                        }
                        size="lg"
                        _hover={{
                          bg:
                            colorMode === "dark"
                              ? "secondary_hover_Opacity"
                              : "primaryOpacity_hover",
                        }}
                        _active={{
                          bg:
                            colorMode === "dark"
                              ? "secondary_hover_Opacity"
                              : "primaryOpacity_hover",
                        }}
                      >
                        <span className="me-2">
                          <i className="fas fa-filter"></i>
                        </span>{" "}
                        Filter
                      </MenuButton>
                      <MenuList className="shadow p-2 " minW={170}>
                        <MenuItem
                          p={2}
                          fontSize="17px"
                          className="text-center rounded"
                          color={checkColorMode(colorMode)}
                          _hover={{
                            backgroundColor:
                              colorMode === "dark"
                                ? "secondary_hover_Opacity"
                                : "primaryOpacity_hover",
                          }}
                          onClick={() =>
                            filterStatus(
                              "new",
                              sentQuotation,
                              setFilterSentQuotation,
                              true
                            )
                          }
                        >
                          <Text fontWeight="semibold" w={"100%"}>
                            New
                          </Text>
                        </MenuItem>
                        <MenuItem
                          p={2}
                          fontSize="17px"
                          className="text-center rounded"
                          color={checkColorMode(colorMode)}
                          _hover={{
                            backgroundColor:
                              colorMode === "dark"
                                ? "secondary_hover_Opacity"
                                : "primaryOpacity_hover",
                          }}
                          onClick={() =>
                            filterStatus(
                              "admin-contacted",
                              sentQuotation,
                              setFilterSentQuotation,
                              true
                            )
                          }
                        >
                          <Text fontWeight="semibold" w={"100%"}>
                            {" "}
                            Admin Contacted
                          </Text>
                        </MenuItem>
                        <MenuItem
                          p={2}
                          fontSize="17px"
                          className="text-center rounded"
                          color={checkColorMode(colorMode)}
                          _hover={{
                            backgroundColor:
                              colorMode === "dark"
                                ? "secondary_hover_Opacity"
                                : "primaryOpacity_hover",
                          }}
                          onClick={() =>
                            filterStatus(
                              "canceled",
                              sentQuotation,
                              setFilterSentQuotation,
                              true
                            )
                          }
                        >
                          <Text fontWeight="semibold" w={"100%"}>
                            {" "}
                            Canceled
                          </Text>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            {filterSentQuotation.map((item, index) => (
              <Card
                key={item.id}
                item={item}
                index={index}
                ConsultationType={ConsultationType}
                linkTo={`${url}/view-quotation`}
                withQuotation={true}
              />
            ))}
            {totalResults > 15 ? (
              <Pagination
                pages={pagesNumber}
                nextPage={nextPage}
                prevPage={prevPage}
                specificPage={specificPage}
                currentPage={currentPage}
              />
            ) : (
              ""
            )}
          </div>
        </AnimatedPages>
      );
    } else {
      return (
        <AnimatedPages>
          <EmptyRequests
            headerText="There aren’t any sent quotations"
            bodyText="There aren’t any sent quotations now, you can view new requests by clicking on the button below."
            btnText="View new requests"
            linkTo="/"
          />
        </AnimatedPages>
      );
    }
  };
  return renderView();
};

const mapStateToProps = (state) => {
  return {
    SentQuotation: state.SentQuotation,
    consultationTypes: state.consultationTypes,
  };
};
export default connect(mapStateToProps, {
  getSentQuotationRequests,
  getConsultationsTyeps,
})(SentQuotationsListing);
