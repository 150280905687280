import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";

import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
// import AddReport from "./NotReport/addReport";
import ViewReport from "./ViewReport/viewReport";

const MedicalReport = ({ medicalReport, history }) => {
  const [oneMedicalReport, setOneMedicalReport] = useState(null);
  useEffect(() => {
    if (medicalReport && medicalReport.length) {
      setOneMedicalReport(medicalReport[medicalReport.length - 1]);
    }
  }, [medicalReport]);

  const { path } = useRouteMatch();
  const loaction = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={loaction} key={loaction.pathname}>
        <Route
          exact
          path={path}
          render={() => <ViewReport oneMedicalReport={oneMedicalReport} />}
        />
        {/* <Route path={`${path}/add-report`} component={AddReport} /> */}
      </Switch>
    </AnimatePresence>
  );
};

export default MedicalReport;
