export const pageVariants = {
  in: {
    opacity: 1,
    // x: 0,
    // scaleL: 1,
  },
  out: {
    opacity: 0,
    // x: "-20%",
    // scaleL: 1.3,
  },
};

export const pageTransition = {
  type: "tween",
  ease: "easeOut",
  duration: 0.5,
};

export const cardVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -2000, duration: 5, delay: 15 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000, duration: 5, delay: 3 },
    },
  },
};
// export const cardVariants = {
//   in: {
//     opacity: 1,
//     x: 0,
//     scaleL: 1,
//   },
//   out: {
//     opacity: 0,
//     x: "-20%",
//     scaleL: 1.3,
//   },
// };
// export const cardTransition = {
//   type: "tween",
//   ease: "anticipate",
//   duration: 1.1,
//   velocity: 1,
// };
