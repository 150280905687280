import React from "react";
import { connect } from "react-redux";
import { useRouteMatch, Switch, Route, useLocation } from "react-router-dom";
import AnimatedPages from "../Animation/AnimatedPages";
import { AnimatePresence } from "framer-motion";
import HospitalRequestsList from "./children/HospitalRequestsList";
import AddPatient from "./children/AddPatient";
import ShowSingleRequest from "./children/ShowSingleRequest";
import NotFound from "../NotFound/NotFound";

const HospitalRequests = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  return (
    <AnimatedPages>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path={path} component={HospitalRequestsList} />
          <Route
            path={`${path}/invited-patient/:id`}
            component={ShowSingleRequest}
          />
          <Route path={`${path}/invite-patient`} component={AddPatient} />
          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      </AnimatePresence>
    </AnimatedPages>
  );
};

export default connect()(HospitalRequests);
