import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NotFound from "../../../../../../NotFound/NotFound";
import ListingMedicalReport from "./Children/listingMedicalReport";
// import requestMedicalReport from "./Children/requestMedicalReport";
import ViewOneMedicalReport from "./Children/viewOneMedicalReport";

const MedicalReport = ({ patient ,queryParams}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <ListingMedicalReport patient={patient} queryParams={queryParams} />}
      />
      {/* <Route
        exact
        path={`${path}/request-medical_report`}
        component={requestMedicalReport}
      /> */}
      <Route
        path={`${path}/view-medical_report/:medicalId`}
        render={() => <ViewOneMedicalReport patient={patient} />}
      />
      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

export default MedicalReport;
