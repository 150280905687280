import React from "react";
import { Box, Button, Text, useColorMode } from "@chakra-ui/react";
import notFound from "../../assets/404.svg";
import { style } from "../global/style";
import { useHistory } from "react-router-dom";
const NotFound = () => {
  const history = useHistory();
  const { colorMode } = useColorMode();
  return (
    <div className="container my-5">
      <div className="row m-0 justify-content-center">
        <Box
          bg={colorMode === "dark" ? "black" : "white"}
          className="col-md-6 col-12 p-3 pb-5 rounded shadow row m-0"
        >
          <img src={notFound} alt="NotFound" />

          <Text
            color={style.primary}
            fontWeight="bold"
            fontSize="xl"
            className="text-center mb-4"
          >
            The page you requested was not found
          </Text>
          <Box className="col-12 text-center">
            <Button
              w="60%"
              size="lg"
              bg={style.secondary}
              color={"#fff"}
              _hover={{ color: "#fff", bg: style.secondary_hover }}
              onClick={() => history.goBack()}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default NotFound;
