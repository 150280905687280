import React from "react";
import { Input, Text, useColorMode } from "@chakra-ui/react";
import { style } from "../../../../../../global/style";

import "./repeated.scss";
import {
  checkBgColorMode,
  checkColorMode,
} from "../../../../../../global/changeColor";
function HeaderSection({ headerName, onInputChange, buttonLink, question }) {
  const { colorMode } = useColorMode();
  return (
    <div className="h4 py-3 fw-bold d-flex flex-wrap align-items-center justify-content-between">
      <Text
        color={checkColorMode(colorMode)}
        fontWeight="semibold"
        className="my-2"
      >
        {headerName}{" "}
      </Text>
      <div className="d-flex align-items-center my-2">
        <div className="me-3 input-search">
          <Input
            w="300px"
            fontSize="15px"
            placeholder="Search"
            size="lg"
            className="ps-5"
            bg={checkBgColorMode(colorMode)}
            onChange={onInputChange}
          />
          <span className="icon-search">
            <i className="fas fa-search"></i>
          </span>
        </div>
        {/* <div className="">
          <Button
            className="rounded text-white "
            backgroundColor={style.primary}
            size="lg"
            _hover={{ backgroundColor: style.primary_hover }}
            _active={{ backgroundColor: style.primary }}
            as={Link}
            to={buttonLink}
            fontSize="17px"
          >
            <span className="me-2">
              <i className="fas fa-plus"></i>
            </span>{" "}
            {question ? "Ask" : "Request"}
          </Button>
        </div> */}
      </div>
    </div>
  );
}

export default HeaderSection;
