import React from "react";

import { Text, Box, useColorMode } from "@chakra-ui/react";

import { style } from "../../../../../../global/style";
import {
  pageTransition,
  pageVariants,
} from "../../../../../../global/Animations/Animations";
import { motion } from "framer-motion";
import {
  checkBgColorMode,
  checkColorMode,
} from "../../../../../../global/changeColor";

const EmptyMedical = ({ name, image, LinkTo }) => {
  const { colorMode } = useColorMode();
  return (
    <motion.div
      className="my-3"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Text
        fontSize="2xl"
        fontWeight="semibold"
        color={checkColorMode(colorMode)}
      >
        {name}
      </Text>
      <Box
        bg={checkBgColorMode(colorMode)}
        className="col-md-12 p-3 rounded row m-0 my-3 align-items-center justify-content-center"
      >
        <Box className="d-flex justify-content-center mt-5">
          <img src={image} alt={name} />
        </Box>

        <Box className="text-center">
          <Text fontSize={"2xl"} fontWeight="bold" className="mt-5">
            {name}
          </Text>
          <Text fontSize={"sm"} className="mt-1" color={style.smallTextGray}>
            There are no {name}
          </Text>
        </Box>
        {/* <Button
          as={Link}
          to={`${LinkTo}`}
          bg={style.primary}
          color={style.white}
          _hover={{ bg: style.primary_hover, color: style.white }}
          size="lg"
          className="mb-5 mt-4"
        >
          <span className="me-2">
            <i className="fas fa-plus"></i>
          </span>{" "}
          Request
        </Button> */}
      </Box>
    </motion.div>
  );
};

export default EmptyMedical;
