import React from "react";
import { useParams } from "react-router-dom";

import NotFound from "../../NotFound/NotFound";
import MedicalRecord from "./Children/MedicalRecords/MedicalRecord";
import Readings from "./Children/Readings/Readings";
import Requests from "./Children/Requests/Requests";
import MedicalReport from "./Children/MedicalReport/MedicalReport";
import AddQuotation from "./OverViewAddQuotation/AddQuotation";

const OverViewChild = ({
  requestes,
  medicalReport,
  readings,
  history,
  patient,
  queryParams,
}) => {
  let { child } = useParams();
  const renderComponent = () => {
    switch (child) {
      case "requestes":
        return <Requests history={history} requestes={requestes} />;

      case "send-quotation":
        return <AddQuotation history={history} requestes={requestes} />;

      case "medical-report":
        return (
          <MedicalReport history={history} medicalReport={medicalReport} />
        );
      case "readings":
        return (
          <Readings
            history={history}
            readings={readings}
            requestes={requestes}
            patient={patient}
          />
        );
      case "medical-record":
        return <MedicalRecord patient={patient} queryParams={queryParams} />;
      default:
        return <NotFound />;
    }
  };

  return <div>{renderComponent()}</div>;
};

export default OverViewChild;
