import React from "react";

import { Switch, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

import MainNewRequests from "../components/HK-Requests/MainPage";
import Login from "../components/Login/Login";
import ForgetPassword from "../components/Forget-Password/ForgetPassword";
import HospitalRequests from "../components/Hospital-Requests/HospitalRequests";
import SentQuotations from "../components/Sent-Quotations/SentQuotations";
import BookedRequests from "../components/Booked-Requests/BookedRequests";
import Profile from "../components/Profile/Profile";
import RequestOverView from "../components/RequestOverView/RequestOverView";
import NotFound from "../components/NotFound/NotFound";

const RenderRoute = () => {
  const location = useLocation();
  return (
    <Switch location={location} key={location.pathname}>
      <ProtectedRoute exact path="/" component={MainNewRequests} />
      <ProtectedRoute path="/new-requests" component={MainNewRequests} />
      <Route path="/login" component={Login} />
      <Route path="/forgetpassword" component={ForgetPassword} />
      <ProtectedRoute path="/hospital_requests" component={HospitalRequests} />
      <ProtectedRoute path="/over-view/:id" component={RequestOverView} />
      <ProtectedRoute path="/sent_quotations" component={SentQuotations} />
      <ProtectedRoute path="/booked_requests" component={BookedRequests} />
      <ProtectedRoute path="/profile" component={Profile} />
      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};
export default RenderRoute;
