import { types } from "../types/types";
const { readingTypes } = types;

const INIT_HOSPITALE_STATE = {
  loading: false,
  error: null,
  data: null,
};

export const getPatientsReadingList = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case readingTypes.GET_READINGS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case readingTypes.GET_READINGS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case readingTypes.GET_READINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
