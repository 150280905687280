import { combineReducers } from "redux";

import {
  loginReducer,
  logOutReducer,
  forgetPasswordReducer,
} from "./hospital_auth";
import {
  getHospitaleProfile,
  getHospitalRequests,
  getNEWHospitalRequests,
  getSingleNewRequrstReducer,
  rejectRequestReducer,
  sendRequestReducer,
  getSentQuotationRequestsReducer,
  getAnyRequestWithQuotationReducer,
  getBookedQuotationRequestsReducer,
  updateHospitaleProfile,
  updateHospitaleProfilePicture,
  changePasswordReducer,
} from "./hospitale_reducers";
import {
  generateInformationShareToken,
  getMedicalInformationFileLink,
  getPatientsInvitationList,
  getSinglePatientsInvitation,
  invitePatient,
} from "./patientReducers";
import {
  getConsultationTypeListReducer,
  getPatientTypesReducer,
} from "./common";

import {
  getOneConditionReducer,
  getOneAllergyReducer,
  getOneEcgReducer,
  getOneLabResultReducer,
  getOneMedicalReportReducer,
  getOneXrayReducer,
  getOneRadiologyReducer,
  getRadiologyListReducer,
  getAllergyListReducer,
  getConditionListReducer,
  getEcgListReducer,
  getLabResultListReducer,
  getMedicalReportListReducer,
  getXrayListReducer,
} from "./medicalRecordReducers";
import { getPatientsReadingList } from "./ReadingsReducers";

export default combineReducers({
  // Auth
  login: loginReducer,
  logOut: logOutReducer,
  forgetPassword: forgetPasswordReducer,
  /// hospital
  HospitaleProfile: getHospitaleProfile,
  HospitalRequests: getHospitalRequests,
  newHospitalRequests: getNEWHospitalRequests,
  SingleNewRequrst: getSingleNewRequrstReducer,
  rejectedRequest: rejectRequestReducer,
  sendedRequest: sendRequestReducer,
  SentQuotation: getSentQuotationRequestsReducer,
  BookedQuotation: getBookedQuotationRequestsReducer,
  AnyRequestWithQuotation: getAnyRequestWithQuotationReducer,
  updatedProfile: updateHospitaleProfile,
  updatedProfilePicture: updateHospitaleProfilePicture,
  passwordChanged: changePasswordReducer,
  // patient
  ShareToken: generateInformationShareToken,
  MedicalFileLink: getMedicalInformationFileLink,
  InvitationList: getPatientsInvitationList,
  SingleInvitation: getSinglePatientsInvitation,
  InvitedPatient: invitePatient,
  //Common
  consultationTypes: getConsultationTypeListReducer,
  patientTypes: getPatientTypesReducer,

  // medical record
  OneCondition: getOneConditionReducer,
  OneAllergy: getOneAllergyReducer,
  OneEcg: getOneEcgReducer,
  OneLabResult: getOneLabResultReducer,
  OneMedicalReport: getOneMedicalReportReducer,
  OneXray: getOneXrayReducer,
  OneRadiology: getOneRadiologyReducer,
  RadiologyList: getRadiologyListReducer,
  AllergyList: getAllergyListReducer,
  ConditionList: getConditionListReducer,
  EcgList: getEcgListReducer,
  LabResultList: getLabResultListReducer,
  MedicalReportList: getMedicalReportListReducer,
  XrayList: getXrayListReducer,

  // reading
  ReadingList: getPatientsReadingList,
});
