import React, { useEffect, useState } from "react";
import { Button, Text, useToast, Box, useColorMode } from "@chakra-ui/react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getHospitalProfile,
  updateHospitalProfile,
} from "../../../../../store/actions";
import { style } from "../../../../global/style";
import { InputControl } from "formik-chakra-ui";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../global/changeColor";

const SubmitSchema = Yup.object().shape({
  country: Yup.string().min(3).required("Required"),
  city: Yup.string().min(3).required("Required"),
  street: Yup.string().min(3).required("Required"),
  postalCode: Yup.string().min(3).required("Required"),
});

const Address = ({
  hospitalProfile,
  updateHospitalProfile,
  getHospitalProfile,
  updatedProfile,
}) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const history = useHistory();
  const [edite, setEdite] = useState(false);
  const [initValue, setInitValue] = useState({
    country: "",
    city: "",
    street: "",
    postalCode: "",
  });

  const onFormSubmit = (value) => {
    const data = {
      ...hospitalProfile,
      address: value,
      country: value.country,
      city: value.city,
      street: value.street,
      postal_code: value.postalCode,
    };
    updateHospitalProfile(
      data,
      history,
      toast,
      null,
      setEdite,
      getHospitalProfile
    );
  };
  useEffect(() => {
    if (hospitalProfile) {
      setInitValue({
        ...initValue,
        country: hospitalProfile.country,
        city: hospitalProfile.city,
        street: hospitalProfile.street,
        postalCode: hospitalProfile.postalCode,
      });
    }
  }, [hospitalProfile]);
  const renderView = () => {
    if (edite) {
      return (
        <Formik
          initialValues={initValue}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={SubmitSchema}
        >
          {({ values, handleBlur, handleChange }) => (
            <Form>
              <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                <div className="my-2">
                  <Text
                    fontSize="lg"
                    color={style.secondary}
                    fontWeight="semibold"
                  >
                    {" "}
                    My Address
                  </Text>
                </div>
                <div className="my-2">
                  <Button
                    width={"50px"}
                    height="50px"
                    bg="#f3e9eb"
                    _hover={{ bg: "#f3d3da" }}
                    fontSize="20px"
                    color="#D42C51"
                    className="me-2"
                    onClick={() => setEdite(false)}
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </Button>

                  <Button
                    width={"50px"}
                    height="50px"
                    bg={style.secondary}
                    _hover={{ bg: style.secondary_hover }}
                    fontSize="20px"
                    type="submit"
                    color="#fff"
                    isLoading={updatedProfile?.loading}
                  >
                    <i className="fas fa-save"></i>
                  </Button>
                </div>
              </div>

              <div className="col-12 row m-0">
                <div className="col-md-6 mt-3 ">
                  <label className="chakra-form__label mb-2" htmlFor="country">
                    Country
                  </label>
                  <CountryDropdown
                    classes={`${
                      colorMode === "dark" ? "css-xpongc" : "css-1c6j008"
                    } text-secondary`}
                    name="country"
                    defaultOptionLabel="Select a country"
                    value={values.country}
                    onChange={(_, e) => handleChange(e)}
                    onBlur={(_, e) => handleBlur(e)}
                  />

                  <ErrorMessage
                    name="country"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "14px" }}
                  />
                </div>

                <div className="col-md-6 mt-3 ">
                  <label className="chakra-form__label mb-2" htmlFor="country">
                    City
                  </label>
                  <RegionDropdown
                    classes={`${
                      colorMode === "dark" ? "css-xpongc" : "css-1c6j008"
                    } text-secondary`}
                    name="city"
                    blankOptionLabel="No country selected"
                    defaultOptionLabel="Now select a City"
                    country={values.country}
                    value={values.city}
                    onChange={(_, e) => handleChange(e)}
                    onBlur={(_, e) => handleBlur(e)}
                  />

                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "14px" }}
                  />
                </div>

                <div className="col-md-6 mt-3 ">
                  <InputControl
                    name={"street"}
                    label="Street"
                    inputProps={{ placeholder: "Street", disabled: false }}
                  />
                </div>

                <div className="col-md-6 mt-3 ">
                  <InputControl
                    name={"postalCode"}
                    label="Postal Code"
                    inputProps={{
                      placeholder: "Postal Code",
                      disabled: false,
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    } else {
      return (
        <>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Text fontSize="lg" color="secondary" fontWeight="semibold">
              {" "}
              My Address
            </Text>
            <Button
              width={"50px"}
              height="50px"
              bg={checkColorMode(colorMode)}
              _hover={{ bg: checkColorModeHover(colorMode) }}
              fontSize="17px"
              color={style.white}
              onClick={() => setEdite(true)}
            >
              <i className="fas fa-pen"></i>
            </Button>
          </div>

          <div className="col-12 d-flex flex-wrap">
            <div className="col-md-4 col-12 p-0 mt-3">
              <Text fontWeight="semibold" color={style.smallTextGray}>
                Country
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {hospitalProfile ? hospitalProfile.country : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0 mt-3">
              <Text fontWeight="semibold" color={style.smallTextGray}>
                City
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {hospitalProfile ? hospitalProfile.city : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0  mt-3">
              <Text fontWeight="semibold" color={style.smallTextGray}>
                Street
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {hospitalProfile ? hospitalProfile.street : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0  mt-4">
              <Text fontWeight="semibold" color={style.smallTextGray}>
                Postel code
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {hospitalProfile ? hospitalProfile.postalCode : "null"}
              </Text>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="MyInfo rounded shadow-sm p-4  row m-0 flex-wrap mb-4"
    >
      {renderView()}
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    updatedProfile: state.updatedProfile,
  };
};
export default connect(mapStateToProps, {
  getHospitalProfile,
  updateHospitalProfile,
})(Address);
