import React, { useEffect, useState } from "react";
import { navList } from "./navList";
import { Link, NavLink, useHistory } from "react-router-dom";
import { logOut } from "../../store/actions";
import logo from "../../assets/logo.svg";
import logo_dark from "../../assets/logo_dark.svg";
import {
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Divider,
  Text,
  Circle,
  Image,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import { style } from "../global/style";
import "./header.scss";
import { connect } from "react-redux";
import DrawerComponent from "./Drawer.js";
import { checkBgColorMode } from "../global/changeColor";

const Header = ({ isLoggedIn, setIsLoggedIn, hospitaleProfile, logOut }) => {
  const [profile, setProfile] = useState({});
  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    if (hospitaleProfile) {
      const { loading, error, data } = hospitaleProfile;
      if (data) {
        setProfile(data);
      }
    }
  }, [hospitaleProfile]);

  const navLinkes = () => {
    return (
      <>
        <div className="d-none d-lg-flex d-flex col-sm-10 col-12 row align-items-center justify-content-between m-0">
          <ul className="col-8 navbar-nav flex-row m-auto justify-content-around">
            <li className="nav-item ">
              <Text
                as={NavLink}
                to={"/new-requests"}
                activeClassName={
                  colorMode === "dark"
                    ? "header-links-active-dark"
                    : "header-links-active"
                }
                className={`m-1 header-links ${
                  colorMode === "dark" ? "header-links-dark" : ""
                }`}
              >
                New Requests
              </Text>
            </li>
            {navList.map((link, index) => (
              <li className="nav-item " key={index}>
                <Text
                  as={NavLink}
                  to={link.linkTo}
                  activeClassName={
                    colorMode === "dark"
                      ? "header-links-active-dark"
                      : "header-links-active"
                  }
                  className={`m-1 header-links ${
                    colorMode === "dark" ? "header-links-dark" : ""
                  }`}
                >
                  {link.text}
                </Text>
              </li>
            ))}
          </ul>
          <div className="col-4 p-0 d-flex justify-content-between">
            <Menu>
              <MenuButton as={Button} variant="ghost">
                <div className="d-flex align-items-center flex-wrap">
                  <div className="me-1">
                    <Avatar
                      size="sm"
                      name={profile ? profile.name : null}
                      src={profile ? profile.picture : null}
                    />
                  </div>
                  <div className="ms-1 bg-d">
                    <Text
                      fontSize={{
                        base: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "16px",
                      }}
                    >
                      {profile ? profile.name : ""}
                    </Text>
                    <Text
                      fontSize="13px"
                      color="gray.400"
                      className="text-start userType"
                    >
                      {localStorage.getItem("userType")
                        ? localStorage.getItem("userType")
                        : ""}
                    </Text>
                  </div>
                </div>
              </MenuButton>

              <MenuList className="menu-notification p-1 shadow">
                <MenuItem className="d-flex flex-wrap" maxW="250px">
                  <div className="w-100 text-center">
                    <Avatar
                      size="md"
                      name={profile ? profile.name : null}
                      src={profile ? profile.picture : null}
                    />
                  </div>
                  <Text
                    fontWeight="semibold"
                    color={style.secondary}
                    className="w-100 my-3 text-center"
                  >
                    {profile ? profile.name : null}
                  </Text>
                </MenuItem>

                <MenuItem
                  as={Link}
                  to="/profile"
                  className="nav-link p-3 w-100  d-flex justify-content-between "
                >
                  <div className="d-flex">
                    <Text className="me-3" color="#979797">
                      <i className="fa fa-user"></i>
                    </Text>
                    <Text fontSize="15px" className="me-1" color="#979797">
                      My Info
                    </Text>
                  </div>
                  <Text color="#979797">
                    <i className="fas fa-chevron-right"></i>
                  </Text>
                </MenuItem>

                <MenuItem
                  as={Link}
                  to="/profile/change_password"
                  className="nav-link p-3 w-100  d-flex justify-content-between"
                >
                  <div className="d-flex">
                    <Text className="me-3" color="#979797">
                      <i className="fa fa-unlock-alt"></i>
                    </Text>
                    <Text fontSize="15px" className="me-1" color="#979797">
                      Change Password
                    </Text>
                  </div>
                  <Text color="#979797">
                    <i className="fas fa-chevron-right"></i>
                  </Text>
                </MenuItem>

                <MenuItem
                  as={Link}
                  to="/login"
                  className="nav-link p-3 w-100  d-flex justify-content-between"
                  onClick={() => {
                    logOut(history);
                    setIsLoggedIn(false);
                  }}
                >
                  <div className="d-flex">
                    <Text className="me-3" color="#979797">
                      <i className="fas fa-sign-out-alt"></i>{" "}
                    </Text>
                    <Text fontSize="15px" className="me-1" color="#979797">
                      Logout
                    </Text>
                  </div>
                  <Text color="#979797">
                    <i className="fas fa-chevron-right"></i>
                  </Text>
                </MenuItem>
              </MenuList>
            </Menu>
            <Button
              className="ms-2"
              maxW="20px"
              onClick={() => toggleColorMode()}
            >
              {colorMode === "dark" ? (
                <span>
                  <i className="fas fa-moon"></i>
                </span>
              ) : (
                <span>
                  <i className="fas fa-moon"></i>
                </span>
              )}
            </Button>
          </div>
        </div>

        <div className="d-xl-none d-lg-none d-xl-flex col-5 p-0 text-end me-2 ">
          <DrawerComponent setIsLoggedIn={setIsLoggedIn} />
        </div>
      </>
    );
  };

  const renderView = () => {
    if (isLoggedIn) {
      return navLinkes();
    } else {
      return (
        <Button maxW="20px" onClick={() => toggleColorMode()}>
          {colorMode === "dark" ? (
            <span>
              <i className="fas fa-moon"></i>
            </span>
          ) : (
            <span>
              <i className="fas fa-moon"></i>
            </span>
          )}
        </Button>
      );
    }
  };
  return (
    <div className="container-fluid NavBar shadow p-0">
      <Box
        bg={checkBgColorMode(colorMode)}
        className="row justify-content-between align-items-center p-2 m-0"
      >
        <div className="col-lg-2 col-6 me-auto">
          <NavLink to="/" className="">
            <img src={colorMode === "dark" ? logo_dark : logo} alt="logo" />
          </NavLink>
        </div>
        <>{renderView()}</>
      </Box>
    </div>
  );
};

export default connect(null, { logOut })(Header);
