import React, { useRef, useState, useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { style } from "../style";
const AlertComponent = ({ removeOne, nameForAlert }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirm, setConfirm] = useState(false);
  const cancelRef = useRef();
  const onConfirm = () => {
    setConfirm(true);
  };

  useEffect(() => {
    if (confirm) {
      onClose();
      removeOne();
      setConfirm(!confirm);
    }
  }, [confirm]);

  const renderBtnView = () => {
    return (
      <Button
        className="me-2"
        onClick={onOpen}
        variant="ghost"
        _hover={{ opacity: 0.8 }}
        bg="error"
        color="white"
      >
        <span className="me-3">
          <i className="fas fa-xmark"></i>
        </span>
        Reject
      </Button>
    );
  };
  return (
    <>
      {renderBtnView()}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirm Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {` Are you sure you want to Reject this ${nameForAlert}`}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button colorScheme="red" ml={3} onClick={onConfirm}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AlertComponent;
