import { types } from "../types/types";
const { hospitalTypes } = types;

const INIT_HOSPITALE_STATE = {
  loading: false,
  error: null,
  data: null,
};

export const getHospitaleProfile = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_HOSPITAL_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_HOSPITAL_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_HOSPITAL_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const updateHospitaleProfile = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.UPDATE_HOSPITAL_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.UPDATE_HOSPITAL_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.UPDATE_HOSPITAL_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const updateHospitaleProfilePicture = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.PROFILE_PICTURE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_HOSPITAL_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.PROFILE_PICTURE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const changePasswordReducer = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.CHANGE_PASSWORD_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getHospitalRequests = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_HOSPITAL_REQUESTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_HOSPITAL_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_HOSPITAL_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getNEWHospitalRequests = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_NEW_HOSPITAL_REQUESTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_NEW_HOSPITAL_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_NEW_HOSPITAL_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getSingleNewRequrstReducer = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_SINGLE_NEW_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_SINGLE_NEW_REQUEST_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_SINGLE_NEW_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const rejectRequestReducer = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.REJECT_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.REJECT_REQUEST_LOADING:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.REJECT_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const sendRequestReducer = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.SEND_QUOTATION_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.SEND_QUOTATION_REQUEST_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.SEND_QUOTATION_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getSentQuotationRequestsReducer = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_SENT_QUOTATION_REQUESTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_SENT_QUOTATION_REQUESTS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_SENT_QUOTATION_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getBookedQuotationRequestsReducer = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_BOOKED_QUOTATION_REQUESTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_BOOKED_QUOTATION_REQUESTS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_BOOKED_QUOTATION_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getAnyRequestWithQuotationReducer = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_ANY_QUOTATION_REQUESTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_ANY_QUOTATION_REQUESTS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_ANY_QUOTATION_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
