import axios from "axios";
import { types } from "../types/types";
import { handeleError, handeleSuccess } from "./Toast/toast";
const { readingTypes } = types;

const API_URL = process.env.REACT_APP_API_URL;

export const getPatientRreadingList = (patientId) => {
  return async (dispatch) => {
    dispatch({
      type: readingTypes.GET_READINGS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/readings`,
      });
      dispatch({
        type: readingTypes.GET_READINGS_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: readingTypes.GET_READINGS_ERROR,
        payload: err,
      });
    }
  };
};
