import React from "react";
import { connect } from "react-redux";
import { useRouteMatch, Switch, Route, useLocation } from "react-router-dom";
import AnimatedPages from "../Animation/AnimatedPages";
import { AnimatePresence } from "framer-motion";
import HKRequests from "./HKRequests";
import ViewOneRequest from "./ViewOneRequest";
import NotFound from "../NotFound/NotFound";

const MainNewRequests = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  return (
    <AnimatedPages>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path={path} component={HKRequests} />
          <Route path={`${path}/view-request/:id`} component={ViewOneRequest} />
          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      </AnimatePresence>
    </AnimatedPages>
  );
};

export default connect()(MainNewRequests);
