import axios from "axios";
import { types } from "../types/types";
import { handeleError, handeleSuccess } from "./Toast/toast";
const API_URL = process.env.REACT_APP_API_URL;

const medicalRecordTypes = types.medicalRecord;

export const getConditionList = (toast, history, patientId, pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_CONDITIONS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/conditions?page=${pageNumber}`,
      });
      dispatch({
        type: medicalRecordTypes.GET_CONDITIONS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_CONDITIONS_ERROR,
        payload: error,
      });
    }
  };
};
export const getOneCondition = (id, toast, history, patientId) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_SINGLE_CONDITION_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/conditions/show/${id}`,
      });

      dispatch({
        type: medicalRecordTypes.GET_SINGLE_CONDITION_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_SINGLE_CONDITION_ERROR,
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};
export const getAllergyList = (toast, history, patientId, pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_ALLERGY_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/allergies?page=${pageNumber}`,
      });
      dispatch({
        type: medicalRecordTypes.GET_ALLERGY_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_ALLERGY_ERROR,
        payload: error,
      });
    }
  };
};
export const getOneAllergy = (id, toast, history, patientId) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_SINGLE_ALLERGY_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/allergies/show/${id}`,
      });

      dispatch({
        type: medicalRecordTypes.GET_SINGLE_ALLERGY_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_SINGLE_ALLERGY_ERROR,
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};
export const getEcgList = (toast, history, patientId, pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_ECGS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/ecgs?page=${pageNumber}`,
      });
      dispatch({
        type: medicalRecordTypes.GET_ECGS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_ECGS_ERROR,
        payload: error,
      });
    }
  };
};
export const getOneEcg = (id, toast, history, patientId) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_SINGLE_ECG_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/ecgs/show/${id}`,
      });

      dispatch({
        type: medicalRecordTypes.GET_SINGLE_ECG_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_SINGLE_ECG_ERROR,
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};
export const getLabResultList = (toast, history, patientId, pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_LAB_RESULTS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/lab-results?page=${pageNumber}`,
      });
      dispatch({
        type: medicalRecordTypes.GET_LAB_RESULTS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_LAB_RESULTS_ERROR,
        payload: error,
      });
    }
  };
};
export const getOneLabResult = (id, toast, history, patientId) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_SINGLE_LABRESULT_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/lab-results/show/${id}`,
      });

      dispatch({
        type: medicalRecordTypes.GET_SINGLE_LABRESULT_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_SINGLE_LABRESULT_ERROR,
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};
export const getMedicalReportList = (
  toast,
  history,
  patientId,
  pageNumber = 1
) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_MEDICAL_REPORTS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/medical-reports?page=${pageNumber}`,
      });
      dispatch({
        type: medicalRecordTypes.GET_MEDICAL_REPORTS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_MEDICAL_REPORTS_ERROR,
        payload: error,
      });
    }
  };
};
export const getOneMedicalReport = (id, toast, history, patientId) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_SINGLE_MEDICALREPORT_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/medical-reports/show/${id}`,
      });

      dispatch({
        type: medicalRecordTypes.GET_SINGLE_MEDICALREPORT_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_SINGLE_MEDICALREPORT_ERROR,
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};
export const getXrayList = (toast, history, patientId, pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_XRAYS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/xrays?page=${pageNumber}`,
      });
      dispatch({
        type: medicalRecordTypes.GET_XRAYS_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_XRAYS_ERROR,
        payload: error,
      });
    }
  };
};
export const getOneXray = (id, toast, history, patientId) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_SINGLE_XRAY_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/xrays/show/${id}`,
      });

      dispatch({
        type: medicalRecordTypes.GET_SINGLE_XRAY_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_SINGLE_XRAY_ERROR,
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};

export const getRadiologyList = (toast, history, patientId, pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_RADIOLOGY_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/radiology?page=${pageNumber}`,
      });
      dispatch({
        type: medicalRecordTypes.GET_RADIOLOGY_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_RADIOLOGY_ERROR,
        payload: error,
      });
    }
  };
};

export const getOneRadiology = (id, toast, history, patientId) => {
  return async (dispatch) => {
    dispatch({
      type: medicalRecordTypes.GET_SINGLE_RADIOLOGY_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}/radiology/show/${id}`,
      });
      dispatch({
        type: medicalRecordTypes.GET_SINGLE_RADIOLOGY_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      //   history.push("/medical-record/conditions");
      dispatch({
        type: medicalRecordTypes.GET_SINGLE_RADIOLOGY_ERROR,
        payload: error,
      });
    }
  };
};
