import React, { useState } from "react";
import { Text, Button, useToast, Box, useColorMode } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";

import * as Yup from "yup";
import { connect } from "react-redux";
import PasswordField from "../../global/passwordField";
import { changePassword, logOut } from "../../../store/actions";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../global/changeColor";

const SubmitSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, "Old password must be at least 8 characters")
    .required("Required"),
  password: Yup.string()
    .min(8, "New password must be at least 8 characters")
    .required("Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});
const ChangePassword = ({ history, changePassword, passwordChanged }) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [initValue] = useState({
    oldPassword: "",
    password: "",
    passwordConfirmation: "",
  });
  const onFormSubmit = (value) => {
    const submitVal = {
      oldPassword: value.oldPassword,
      password: value.password,
    };
    changePassword(submitVal, toast, history, logOut);
  };

  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="ChangePassword  rounded shadow-sm p-4  d-flex flex-wrap ms-2"
    >
      <Formik
        initialValues={initValue}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={SubmitSchema}
      >
        <Form className="col-12 row m-0">
          <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
            <Text
              fontSize="lg"
              fontWeight="semibold"
              color="secondary"
              className="my-2"
            >
              {" "}
              Change Password
            </Text>
            <div className="my-2">
              <Button
                width={"50px"}
                height="50px"
                bg="#f3e9eb"
                _hover={{ bg: "#f3d3da" }}
                fontSize="20px"
                color="#D42C51"
                className="me-2"
              >
                <Link to={"/profile"} className="nav-link text-error">
                  <i className="fas fa-times"></i>
                </Link>
              </Button>
              <Button
                width={"50px"}
                height="50px"
                bg={checkColorMode(colorMode)}
                _hover={{ bg: checkColorModeHover(colorMode) }}
                fontSize="20px"
                type="submit"
                color="#fff"
                isLoading={passwordChanged?.loading}
              >
                <i className="fas fa-save"></i>
              </Button>
            </div>
          </div>
          <div className="">
            <div className="col-md-6">
              <PasswordField
                label="Old Password"
                placeholder="Old Password"
                name="oldPassword"
              />
            </div>
            <div className="col-md-6">
              <PasswordField
                label="New Password"
                placeholder="New Password"
                name="password"
              />
            </div>
            <div className="col-md-6">
              <PasswordField
                label="Re-enter New password"
                placeholder="Re-enter New password"
                name="passwordConfirmation"
              />
            </div>
          </div>
        </Form>
      </Formik>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    passwordChanged: state.passwordChanged,
  };
};
export default connect(mapStateToProps, { changePassword })(ChangePassword);
