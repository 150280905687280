export const types = {
  AuthTypes: {
    // LogIn
    LOGIN_LOADING: "LOGIN_LOADING",
    LOGIN_SUCSES: "LOGIN_SUCSES",
    LOGIN_ERROR: "LOGIN_ERROR",
    // LogOut
    LOGOUT_LOADING: "LOGOUT_LOADING",
    LOGOUT_SUCSES: "LOGOUT_SUCSES",
    LOGOUT_ERROR: "LOGOUT_ERROR",
    // forget password
    FORGET_PASSWORD_LOADING: "FORGET_PASSWORD_LOADING",
    FORGET_PASSWORD_SUCSES: "FORGET_PASSWORD_SUCSES",
    FORGET_PASSWORD_ERROR: "FORGET_PASSWORD_ERROR",
  },
  hospitalTypes: {
    // get hospital profile
    GET_HOSPITAL_PROFILE_LOADING: "GET_HOSPITAL_PROFILE_LOADING",
    GET_HOSPITAL_PROFILE_SUCCESS: "GET_HOSPITAL_PROFILE_SUCCESS",
    GET_HOSPITAL_PROFILE_ERROR: "GET_HOSPITAL_PROFILE_ERROR",
    // update hospital profile
    UPDATE_HOSPITAL_PROFILE_LOADING: "UPDATE_HOSPITAL_PROFILE_LOADING",
    UPDATE_HOSPITAL_PROFILE_SUCCESS: "UPDATE_HOSPITAL_PROFILE_SUCCESS",
    UPDATE_HOSPITAL_PROFILE_ERROR: "UPDATE_HOSPITAL_PROFILE_ERROR",
    // update hospital profile picture
    UPDATE_PROFILE_PICTURE_LOADING: "UPDATE_PROFILE_PICTURE_LOADING",
    UPDATE_PROFILE_PICTURE_SUCCESS: "UPDATE_PROFILE_PICTURE_SUCCESS",
    UPDATE_PROFILE_PICTURE_ERROR: "UPDATE_PROFILE_PICTURE_ERROR",

    // GET HOSPITAL REQUESTS
    GET_HOSPITAL_REQUESTS_LOADING: "GET_HOSPITAL_REQUESTS_LOADING",
    GET_HOSPITAL_REQUESTS_SUCCESS: "GET_HOSPITAL_REQUESTS_SUCCESS",
    GET_HOSPITAL_REQUESTS_ERROR: "GET_HOSPITAL_REQUESTS_ERROR",

    // GET HOSPITAL NEW REQUESTS
    GET_NEW_HOSPITAL_REQUESTS_LOADING: "GET_NEW_HOSPITAL_REQUESTS_LOADING",
    GET_NEW_HOSPITAL_REQUESTS_SUCCESS: "GET_NEW_HOSPITAL_REQUESTS_SUCCESS",
    GET_NEW_HOSPITAL_REQUESTS_ERROR: "GET_NEW_HOSPITAL_REQUESTS_ERROR",

    /// generate-information-share-token
    GENERATE_INFORMATION_SHARE_TOKEN_LOADING:
      "GENERATE_INFORMATION_SHARE_TOKEN_LOADING",
    GENERATE_INFORMATION_SHARE_TOKEN_SUCSES:
      "GENERATE_INFORMATION_SHARE_TOKEN_SUCSES",
    GENERATE_INFORMATION_SHARE_TOKEN_ERROR:
      "GENERATE_INFORMATION_SHARE_TOKEN_ERROR",

    //invitePatient
    INVITE_PATIENT_LOADING: "INVITE_PATIENT_LOADING",
    INVITE_PATIENT_SUCCESS: "INVITE_PATIENT_SUCCESS",
    INVITE_PATIENT_ERROR: "INVITE_PATIENT_ERROR",

    //getPatientsInvitationList
    GET_INVITATION_LIST_LOADING: "GET_INVITATION_LIST_LOADING",
    GET_INVITATION_LIST_SUCSES: "GET_INVITATION_LIST_SUCSES",
    GET_INVITATION_LIST_ERROR: "GET_INVITATION_LIST_ERROR",

    // GET SINGLE INVITATION
    GET_SINGLE_INVITATION_LOADING: "GET_SINGLE_INVITATION_LOADING",
    GET_SINGLE_INVITATION_SUCSES: "GET_SINGLE_INVITATION_SUCSES",
    GET_SINGLE_INVITATION_ERROR: "GET_SINGLE_INVITATION_ERROR",

    // GET MEDICAL INFORMATION FILE LINKE
    GET_MEDICAL_INFORMATION_LOADING: "GET_MEDICAL_INFORMATION_LOADING",
    GET_MEDICAL_INFORMATION_SUCSES: "GET_MEDICAL_INFORMATION_SUCSES",
    GET_MEDICAL_INFORMATION_ERROR: "GET_MEDICAL_INFORMATION_ERROR",

    // GET SINGLE INVITATION
    GET_SINGLE_NEW_REQUEST_LOADING: "GET_SINGLE_NEW_REQUEST_LOADING",
    GET_SINGLE_NEW_REQUEST_SUCSES: "GET_SINGLE_NEW_REQUEST_SUCSES",
    GET_SINGLE_NEW_REQUEST_ERROR: "GET_SINGLE_NEW_REQUEST_ERROR",

    // reject Request
    REJECT_REQUEST_LOADING: "REJECT_REQUEST_LOADING",
    REJECT_REQUEST_SUCSES: "REJECT_REQUEST_SUCSES",
    REJECT_REQUEST_ERROR: "REJECT_REQUEST_ERROR",

    // reject Request
    SEND_QUOTATION_REQUEST_LOADING: "SEND_QUOTATION_REQUEST_LOADING",
    SEND_QUOTATION_REQUEST_SUCSES: "SEND_QUOTATION_REQUEST_SUCSES",
    SEND_QUOTATION_REQUEST_ERROR: "SEND_QUOTATION_REQUEST_ERROR",

    // sent quotations list
    GET_SENT_QUOTATION_REQUESTS_LOADING: "GET_SENT_QUOTATION_REQUESTS_LOADING",
    GET_SENT_QUOTATION_REQUESTS_SUCSES: "GET_SENT_QUOTATION_REQUESTS_SUCSES",
    GET_SENT_QUOTATION_REQUESTS_ERROR: "GET_SENT_QUOTATION_REQUESTS_ERROR",
    // BOOKED quotations list
    GET_BOOKED_QUOTATION_REQUESTS_LOADING:
      "GET_BOOKED_QUOTATION_REQUESTS_LOADING",
    GET_BOOKED_QUOTATION_REQUESTS_SUCSES:
      "GET_BOOKED_QUOTATION_REQUESTS_SUCSES",
    GET_BOOKED_QUOTATION_REQUESTS_ERROR: "GET_BOOKED_QUOTATION_REQUESTS_ERROR",
    // sent quotations list
    GET_ANY_QUOTATION_REQUESTS_LOADING: "GET_ANY_QUOTATION_REQUESTS_LOADING",
    GET_ANY_QUOTATION_REQUESTS_SUCSES: "GET_ANY_QUOTATION_REQUESTS_SUCSES",
    GET_ANY_QUOTATION_REQUESTS_ERROR: "GET_ANY_QUOTATION_REQUESTS_ERROR",

    // update profile pic
    PROFILE_PICTURE_LOADING: "PROFILE_PICTURE_LOADING",
    PROFILE_PICTURE_SUCSES: "PROFILE_PICTURE_SUCSES",
    PROFILE_PICTURE_ERROR: "PROFILE_PICTURE_ERROR",
    // change password
    CHANGE_PASSWORD_LOADING: "CHANGE_PASSWORD_LOADING",
    CHANGE_PASSWORD_SUCSES: "CHANGE_PASSWORD_SUCSES",
    CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  },
  medicalRecord: {
    // allergy
    GET_SINGLE_ALLERGY_LOADING: "GET_SINGLE_ALLERGY_LOADING",
    GET_SINGLE_ALLERGY_SUCSES: "GET_SINGLE_ALLERGY_SUCSES",
    GET_SINGLE_ALLERGY_ERROR: "GET_SINGLE_ALLERGY_ERROR",
    // condition
    GET_SINGLE_CONDITION_LOADING: "GET_SINGLE_CONDITION_LOADING",
    GET_SINGLE_CONDITION_SUCSES: "GET_SINGLE_CONDITION_SUCSES",
    GET_SINGLE_CONDITION_ERROR: "GET_SINGLE_CONDITION_ERROR",
    // ctScan
    GET_SINGLE_CTSCAN_LOADING: "GET_SINGLE_CTSCAN_LOADING",
    GET_SINGLE_CTSCAN_SUCSES: "GET_SINGLE_CTSCAN_SUCSES",
    GET_SINGLE_CTSCAN_ERROR: "GET_SINGLE_CTSCAN_ERROR",
    // ECG
    GET_SINGLE_ECG_LOADING: "GET_SINGLE_ECG_LOADING",
    GET_SINGLE_ECG_SUCSES: "GET_SINGLE_ECG_SUCSES",
    GET_SINGLE_ECG_ERROR: "GET_SINGLE_ECG_ERROR",
    // Lab result
    GET_SINGLE_LABRESULT_LOADING: "GET_SINGLE_LABRESULT_LOADING",
    GET_SINGLE_LABRESULT_SUCSES: "GET_SINGLE_LABRESULT_SUCSES",
    GET_SINGLE_LABRESULT_ERROR: "GET_SINGLE_LABRESULT_ERROR",
    // MEDICAL REPORT
    GET_SINGLE_MEDICALREPORT_LOADING: "GET_SINGLE_MEDICALREPORT_LOADING",
    GET_SINGLE_MEDICALREPORT_SUCSES: "GET_SINGLE_MEDICALREPORT_SUCSES",
    GET_SINGLE_MEDICALREPORT_ERROR: "GET_SINGLE_MEDICALREPORT_ERROR",
    // MRI
    GET_SINGLE_MRI_LOADING: "GET_SINGLE_MRI_LOADING",
    GET_SINGLE_MRI_SUCSES: "GET_SINGLE_MRI_SUCSES",
    GET_SINGLE_MRI_ERROR: "GET_SINGLE_MRI_ERROR",
    // XRAY
    GET_SINGLE_XRAY_LOADING: "GET_SINGLE_XRAY_LOADING",
    GET_SINGLE_XRAY_SUCSES: "GET_SINGLE_XRAY_SUCSES",
    GET_SINGLE_XRAY_ERROR: "GET_SINGLE_XRAY_ERROR",
    // SINGLE RADIOLOGY
    GET_SINGLE_RADIOLOGY_LOADING: "GET_SINGLE_RADIOLOGY_LOADING",
    GET_SINGLE_RADIOLOGY_SUCSES: "GET_SINGLE_RADIOLOGY_SUCSES",
    GET_SINGLE_RADIOLOGY_ERROR: "GET_SINGLE_RADIOLOGY_ERROR",
    //RADIOLOGY list
    GET_RADIOLOGY_LOADING: "GET_RADIOLOGY_LOADING",
    GET_RADIOLOGY_SUCSES: "GET_RADIOLOGY_SUCSES",
    GET_RADIOLOGY_ERROR: "GET_RADIOLOGY_ERROR",
    //allergy list
    GET_ALLERGY_LOADING: "GET_ALLERGY_LOADING",
    GET_ALLERGY_SUCSES: "GET_ALLERGY_SUCSES",
    GET_ALLERGY_ERROR: "GET_ALLERGY_ERROR",
    //CONDITIONS list
    GET_CONDITIONS_LOADING: "GET_CONDITIONS_LOADING",
    GET_CONDITIONS_SUCSES: "GET_CONDITIONS_SUCSES",
    GET_CONDITIONS_ERROR: "GET_CONDITIONS_ERROR",
    //MEDICAL REPORTS list
    GET_MEDICAL_REPORTS_LOADING: "GET_MEDICAL_REPORTS_LOADING",
    GET_MEDICAL_REPORTS_SUCSES: "GET_MEDICAL_REPORTS_SUCSES",
    GET_MEDICAL_REPORTS_ERROR: "GET_MEDICAL_REPORTS_ERROR",
    //X RAY list
    GET_XRAYS_LOADING: "GET_XRAYS_LOADING",
    GET_XRAYS_SUCSES: "GET_XRAYS_SUCSES",
    GET_XRAYS_ERROR: "GET_XRAYS_ERROR",
    //LAB RESULT list
    GET_LAB_RESULTS_LOADING: "GET_LAB_RESULTS_LOADING",
    GET_LAB_RESULTS_SUCSES: "GET_LAB_RESULTS_SUCSES",
    GET_LAB_RESULTS_ERROR: "GET_LAB_RESULTS_ERROR",
    //ECG list
    GET_ECGS_LOADING: "GET_ECGS_LOADING",
    GET_ECGS_SUCSES: "GET_ECGS_SUCSES",
    GET_ECGS_ERROR: "GET_ECGS_ERROR",
  },
  readingTypes: {
    GET_READINGS_LOADING: "GET_READINGS_LOADING",
    GET_READINGS_SUCSES: "GET_READINGS_SUCSES",
    GET_READINGS_ERROR: "GET_READINGS_ERROR",
  },
  commonTypes: {
    // GET_CONSULTATION_TYPE
    GET_CONSULTATION_TYPE_LOADING: "GET_CONSULTATION_TYPE_LOADING",
    GET_CONSULTATION_TYPE_SUCSES: "GET_CONSULTATION_TYPE_SUCSES",
    GET_CONSULTATION_TYPE_ERROR: "GET_CONSULTATION_TYPE_ERROR",

    // GET_PATIENT_TYPE
    GET_PATIENT_TYPE_LOADING: "GET_PATIENT_TYPE_LOADING",
    GET_PATIENT_TYPE_SUCSES: "GET_PATIENT_TYPE_SUCSES",
    GET_PATIENT_TYPE_ERROR: "GET_PATIENT_TYPE_ERROR",
  },
};
