import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../../../global/Animations/Animations";
import { Box, Text, useColorMode } from "@chakra-ui/react";
import { style } from "../../../../global/style";
import ReadingCard from "./ReadingCard";
import heartRateImage from "../../../../../assets/Readings/heartRate.png";
import bloodPressureImage from "../../../../../assets/Readings/bloodPressure.png";
import bloodSugarImage from "../../../../../assets/Readings/bloodSugar.png";
import heightImage from "../../../../../assets/Readings/height.png";
import temperatureImage from "../../../../../assets/Readings/temperature.png";
import weightImage from "../../../../../assets/Readings/weight.png";
import { connect } from "react-redux";
import { getPatientRreadingList } from "../../../../../store/actions";
import { checkColorMode } from "../../../../global/changeColor";

const Readings = ({
  patient,
  history,
  readings,
  requestes,
  getPatientRreadingList,
  ReadingList,
}) => {
  const [readingsList, setReadingsList] = useState([]);
  const [bloodPressure, setBloodPressure] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [heartRate, setHeartRate] = useState();
  const [bloodSugar, setBloodSugar] = useState();
  const [temperature, setTemperature] = useState();
  const { colorMode } = useColorMode();
  useEffect(() => {
    if (patient) getPatientRreadingList(patient.id);
  }, []);
  useEffect(() => {
    if (ReadingList) {
      const { loading, error, data } = ReadingList;
      if (data && data.length) {
        setReadingsList(data);
      }
    }
  }, [ReadingList]);

  useEffect(() => {
    if (readingsList && readingsList.length > 0) {
      setBloodPressure(
        readingsList.find((reading) => {
          return reading.type === "blood-pressure";
        })
      );
      setHeight(
        readingsList.find((reading) => {
          return reading.type === "height";
        })
      );
      setWeight(
        readingsList.find((reading) => {
          return reading.type === "weight";
        })
      );
      setHeartRate(
        readingsList.find((reading) => {
          return reading.type === "heart-rate";
        })
      );
      setBloodSugar(
        readingsList.find((reading) => {
          return reading.type === "blood-sugar";
        })
      );
      setTemperature(
        readingsList.find((reading) => {
          return reading.type === "temperature";
        })
      );
    }
  }, [readingsList]);

  return (
    <motion.div
      className=""
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Box className="row my-3">
        <Text
          fontSize="2xl"
          fontWeight="semibold"
          color={checkColorMode(colorMode)}
        >
          Readings
        </Text>
        <ReadingCard
          item={bloodPressure}
          image={bloodPressureImage}
          name="Blood Pressure"
          feildName="blood-pressure"
          requestes={requestes}
        />
        <ReadingCard
          item={height}
          image={heightImage}
          name="Height"
          feildName="height"
          requestes={requestes}
        />
        <ReadingCard
          item={weight}
          image={weightImage}
          name="Weight"
          feildName="weight"
          requestes={requestes}
        />
        <ReadingCard
          item={heartRate}
          image={heartRateImage}
          name="Heart Rate"
          feildName="heart-rate"
          requestes={requestes}
        />
        <ReadingCard
          item={bloodSugar}
          image={bloodSugarImage}
          name="Blood Sugar"
          feildName="blood-sugar"
          requestes={requestes}
        />
        <ReadingCard
          item={temperature}
          image={temperatureImage}
          name="Temperature"
          feildName="temperature"
          requestes={requestes}
        />
      </Box>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    ReadingList: state.ReadingList,
  };
};
export default connect(mapStateToProps, { getPatientRreadingList })(Readings);
