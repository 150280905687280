export const check_status_string = (status) => {
  switch (status) {
    case "Awaiting to be assigned":
      return { string: "Pending for test", className: "pending" };
    case "Done":
      return {
        string: "Done",
        className: "done",
      };

    default:
      return {
        string: status,
        className: "pending",
      };
  }
};
export const filterStatus = (status, data, setFilterData, withQuotation) => {
  switch (status) {
    case "new":
      if (withQuotation) {
        const newDataQuotation = data.filter(
          (element) => element.hospitalReservationQuotation[0].status === "new"
        );
        setFilterData(newDataQuotation);
      } else {
        const newData = data.filter((element) => element.status === "new");
        setFilterData(newData);
      }

      break;

    case "booked":
      if (withQuotation) {
        const bookedDataQuotation = data.filter(
          (element) =>
            element.hospitalReservationQuotation[0].status === "booked"
        );
        setFilterData(bookedDataQuotation);
      } else {
        const bookedData = data.filter(
          (element) => element.status === "booked"
        );
        setFilterData(bookedData);
      }

      const bookedData = data.filter((element) => element.status === "booked");
      setFilterData(bookedData);
      break;
    case "signed-up":
      const signedUpData = data.filter(
        (element) => element.status === "signed-up"
      );
      setFilterData(signedUpData);
      break;

    case "finished-requests":
      const finishedRequestsData = data.filter(
        (element) => element.status === "finished-requests"
      );
      setFilterData(finishedRequestsData);
      break;

    case "canceled":
      if (withQuotation) {
        const canceledRequestsDataQuotation = data.filter(
          (element) =>
            element.hospitalReservationQuotation[0].status === "canceled"
        );
        setFilterData(canceledRequestsDataQuotation);
      } else {
        const canceledRequestsData = data.filter(
          (element) => element.status === "canceled"
        );
        setFilterData(canceledRequestsData);
      }
      break;

    case "admin-contacted":
      if (withQuotation) {
        const adminDataQuotation = data.filter(
          (element) =>
            element.hospitalReservationQuotation[0].status === "admin-contacted"
        );
        setFilterData(adminDataQuotation);
      } else {
        const adminData = data.filter(
          (element) => element.status === "admin-contacted"
        );
        setFilterData(adminData);
      }

      break;

    default:
      setFilterData(data);
      break;
  }
};
