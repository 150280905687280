import React, { useRef, useEffect } from "react";
import { Box, Button, Text, useColorMode } from "@chakra-ui/react";
import moment from "moment";
import { style } from "../../../../../global/style";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { showDownloadBtn, showImageOrPdf } from "../../../../../global/general";
import EmptyReport from "../NotReport/EmptyReport";
import { Link, useRouteMatch } from "react-router-dom";
import { motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../../../../global/Animations/Animations";
import {
  checkBgColorMode,
  checkColorMode,
} from "../../../../../global/changeColor";
const ViewReport = ({ oneMedicalReport }) => {
  const ref = useRef();
  const { url } = useRouteMatch();
  const { colorMode } = useColorMode();
  const reportRef = useRef();

  useEffect(() => {
    if (reportRef && oneMedicalReport) {
      reportRef.current.innerHTML = "";
      reportRef.current.insertAdjacentHTML("beforeend", oneMedicalReport.note);
    }
  }, [reportRef, oneMedicalReport]);

  const renderView = () => {
    if (oneMedicalReport) {
      return (
        <motion.div
          className="my-3"
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <Box className="d-flex justify-content-between align-items-center">
            <Text
              fontSize="2xl"
              fontWeight="semibold"
              color={checkColorMode(colorMode)}
            >
              Doctor Report
            </Text>
            {/* <Button
              as={Link}
              to={`${url}/add-report`}
              bg={style.primary}
              color={style.white}
              size="lg"
              _hover={{ bg: style.primary_hover, color: style.white }}
            >
              <span className="me-2">
                <i className="fas fa-plus"></i>
              </span>{" "}
              Add
            </Button> */}
          </Box>

          <Box
            bg={checkBgColorMode(colorMode)}
            className="col-md-12 p-3 rounded row m-0 my-3 align-items-center"
          >
            <Box className="row m-0 align-items-center">
              <Box className="col-md-6">
                <Text fontSize="xl" color={checkColorMode(colorMode)}>
                  {oneMedicalReport.title}
                </Text>
                <Text fontSize="xs" color={style.smallTextGray}>
                  {moment
                    .unix(oneMedicalReport.createdAt)
                    .format("DD/MM/YYYY hh:mm A")}
                </Text>
              </Box>
              <Box className="col-md-6 row m-0 justify-content-end">
                {showDownloadBtn(oneMedicalReport)}

                <ReactToPrint content={() => ref.current}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button
                        className="rounded m-2"
                        bg={style.secondary}
                        color="#fff"
                        fontSize="14px"
                        _hover={{ bg: style.secondary_hover }}
                        minW="140px"
                        onClick={handlePrint}
                      >
                        <span className="me-4">
                          <i className="fas fa-print"></i>
                        </span>{" "}
                        Print
                      </Button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </Box>
            </Box>

            <Box className="row m-0 align-items-center" ref={ref}>
              <Box>
                <Box
                  bg={colorMode === "dark" ? "" : style.bg_Light_Gray}
                  border={`1px solid ${style.smallTextGrayLightBorder}`}
                  boxShadow="xs"
                  className="col-12 rounded p-3 mt-3"
                  ref={reportRef}
                ></Box>
                <Box className="p-y mt-3">
                  {showImageOrPdf(oneMedicalReport)}
                </Box>
              </Box>
            </Box>
          </Box>
        </motion.div>
      );
    } else {
      return <EmptyReport />;
    }
  };
  return renderView();
};

export default ViewReport;
