import React from "react";
import { connect } from "react-redux";
import { useRouteMatch, Switch, Route, useLocation } from "react-router-dom";
import AnimatedPages from "../Animation/AnimatedPages";
import { AnimatePresence } from "framer-motion";

import SentQuotationsListing from "./SentQuotationsListing";
import ViewQuotation from "./ViewQuotation";

const SentQuotations = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  return (
    <AnimatedPages>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path={path} component={SentQuotationsListing} />
          <Route
            path={`${path}/view-quotation/:id`}
            component={ViewQuotation}
          />
        </Switch>
      </AnimatePresence>
    </AnimatedPages>
  );
};

export default connect()(SentQuotations);
