import { Box, Divider, Text, useColorMode } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { style } from "../../../../global/style";
import { checkReaddingType } from "./helperImage";
import {
  checkBgColorMode,
  checkColorMode,
} from "../../../../global/changeColor";

const ReadingCard = ({ name, image, icon, item, feildName, requestes }) => {
  const { colorMode } = useColorMode();
  const filterRequests = (type) => {
    if (requestes) {
      const exist = requestes.find(
        (element) => element.scan_type === `reading-${type}`
      );
      if (exist) {
        return <span className="done rounded">Requested</span>;
      } else {
        return (
          // <ReadingModal
          //   noReading={true}
          //   img={image}
          //   name={name}
          //   icon={icon}
          //   feildName={feildName}
          //   itemId={item && item.id ? item.id : null}
          // />
          ""
        );
      }
    }
  };

  return (
    <Box className="col-lg-4 col-md-6 col-12 ">
      <Box
        bg={checkBgColorMode(colorMode)}
        className="rounded shadow p-4  row mx-0 my-3"
        minH={"215px"}
      >
        <div className="col-12 d-flex align-items-center">
          <Box
            className=" rounded-circle d-flex justify-content-center align-items-center text-white"
            style={{
              minWidth: "80px",
              minHeight: "80px",
            }}
            bg={checkColorMode(colorMode)}
          >
            <img src={image} alt={name} />
          </Box>
          <div className="ms-4">
            <Text
              fontSize="19px"
              className="capitalize"
              fontWeight="bold"
              color={checkColorMode(colorMode)}
            >
              {name}
            </Text>
          </div>
        </div>

        <div className="col-12 my-3">
          <Divider color="gray.200" />
        </div>

        {item ? (
          <div className="col-12 row  align-items-center">
            <div className="col-12 ">
              <Text
                fontSize="2xl"
                className="fw-bold "
                color={checkColorMode(colorMode)}
              >
                {item.value}
                <span className="ms-2" style={{ fontSize: "12px" }}>
                  {checkReaddingType(item.type).text}
                </span>
              </Text>
            </div>
            <div className="col-12 fw-600 my-2" style={{ fontSize: "12px" }}>
              {moment.unix(item.updatedAt).format("DD/MM/YYYY hh:mm A")}
            </div>
          </div>
        ) : (
          <div className="text-end">{filterRequests(feildName)}</div>
        )}
      </Box>
    </Box>
  );
};

export default ReadingCard;
