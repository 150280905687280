import React, { useEffect } from "react";
import { style } from "../global/style";
import {
  Text,
  Box,
  Image,
  Circle,
  Button,
  useToast,
  Avatar,
  useColorMode,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  getSinglePatientsInvitation,
  generateInformationShareToken,
  getMedicalInformationFileLink,
  rejectQuotationRequest,
  getConsultationsTyeps,
} from "../../store/actions";
import moment from "moment";
import { connect } from "react-redux";
import AlertComponent from "../global/components/Alert.Component";
import { useParams, useHistory, Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { checkColorMode, checkBgColorMode } from "../global/changeColor";
import { GetSpecialityIcon } from "../global/GetSpecialityIcon.js";

const OverView = ({
  singleInvitation,
  generateInformationShareToken,
  ShareToken,
  getMedicalInformationFileLink,
  MedicalFileLink,
  patient,
  fromNewRequest,
  rejectQuotationRequest,
  rejectedRequest,

  withQuotation,
  HospitalReservationQuotation,

  consultationTypes,
}) => {
  // getConsultationsTyeps

  useEffect(() => {
    getConsultationsTyeps();
  }, [getConsultationsTyeps]);

  useEffect(() => {
    if (ShareToken.data) {
      navigator.clipboard.writeText(
        `https://patient.staging.pat-rec.com/${singleInvitation?.patient?.id}/shared-data?sharedToken=${ShareToken.data}`
      );
    }
  }, [ShareToken]);

  let toast = useToast();
  let params = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { colorMode } = useColorMode();

  const rejectQRequest = () => {
    rejectQuotationRequest(params.id, toast, history, "/");
  };

  return (
    <div className="col-12 row m-0 my-5">
      <Box
        as={motion.div}
        className=" col-12 p-2 py-4 shadow-sm rounded row m-0"
        bg={checkBgColorMode(colorMode)}
      >
        <div className="col-12 p-2 px-3 d-flex flex-wrap align-items-center justify-content-between">
          <Box className="my-2">
            <Text fontSize="2xl" color={style.secondary} fontWeight="bold">
              {console.log(singleInvitation)}
              {singleInvitation?.name ||
                singleInvitation?.patientConsultationRequest?.symptoms ||
                singleInvitation?.note}
            </Text>
            <Text fontSize="xs" color={style.smallTextGray}>
              {moment(singleInvitation?.createdAt * 1000).format(
                "MM/DD/YYYY - hh:mm A"
              )}
            </Text>
          </Box>
          <Box className="pending my-2">
            {singleInvitation?.status.replace(/-/g, " ")}
          </Box>
          <Box className="w-100 mt-2 d-flex align-items-center">
            <Circle size="40px" bg={checkColorMode(colorMode)}>
              {singleInvitation?.patientConsultationRequest
                ?.consultationTypeId ? (
                <img
                  className="image-fluid"
                  src={GetSpecialityIcon(
                    Number(
                      singleInvitation?.patientConsultationRequest
                        ?.consultationTypeId
                    )
                  )}
                  alt="chest"
                />
              ) : (
                ""
              )}
            </Circle>
            <Box className="p-0 ms-2">
              <Text color="gray.400" fontSize="13px" fontWeight="600">
                Speciality
              </Text>
              <Text fontSize="12px" fontWeight="700">
                {consultationTypes.length
                  ? consultationTypes.find(
                      (element) =>
                        element.id ===
                        singleInvitation?.patientConsultationRequest
                          ?.consultationTypeId
                    ).name
                  : ""}
              </Text>
            </Box>
          </Box>
        </div>

        <div className="col-12 p-2 px-2 row align-items-center m-0 my-3">
          {singleInvitation?.preferredCheckInDate ? (
            <div className="col-md-6 col-12 d-flex">
              <Circle size={"45"} color="#fff" bg={style.secondary}>
                <span>
                  <i className="fas fa-calendar-week"></i>
                </span>
              </Circle>
              <Box className="ms-2">
                <Text fontSize="sm" color={style.smallTextGray}>
                  Preferred check in date
                </Text>
                <Text
                  fontSize="sm"
                  color={colorMode === "dark" ? "white" : "black"}
                  fontWeight="semibold"
                >
                  {moment(singleInvitation.preferredCheckInDate * 1000).format(
                    "MM/DD/YYYY"
                  )}
                </Text>
              </Box>
            </div>
          ) : (
            <div className="col-md-6 col-12"></div>
          )}

          {singleInvitation?.patient ? (
            <div className="col-md-6 col-12 d-flex flex-wrap justify-content-around">
              <Button
                fontSize="sm"
                color={checkColorMode(colorMode)}
                variant="gost"
                onClick={() => {
                  getMedicalInformationFileLink(
                    singleInvitation?.patient.id,
                    toast
                  );
                }}
                isLoading={MedicalFileLink.loading}
                className="my-2"
              >
                <span className="me-2">
                  <i className="fas fa-download"></i>
                </span>
                Download patient file
              </Button>
              <Button
                fontSize="sm"
                color={checkColorMode(colorMode)}
                variant="gost"
                onClick={() => {
                  generateInformationShareToken(
                    singleInvitation?.patient.id,
                    toast
                  );
                }}
                isLoading={ShareToken.loading}
                className="my-2"
              >
                <span className="me-2">
                  <i className="fas fa-share"></i>
                </span>
                Share with designated team
              </Button>
            </div>
          ) : null}
        </div>

        <div className="col-12 rounded">
          <Box
            boxShadow="xs"
            className="rounded p-3"
            bg={style.smallTextGrayLight}
            border={`1px solid ${style.smallTextGrayLightBorder}`}
          >
            {singleInvitation?.invitationText ||
              singleInvitation?.patientConsultationRequest?.symptomsExplained ||
              singleInvitation?.note}
          </Box>
        </div>

        {fromNewRequest && (
          <div className="d-flex p-3 justify-content-end">
            <Button
              fontSize={"md"}
              bg={style.green}
              color={style.white}
              _hover={{ opacity: 0.8, color: "#fff" }}
              className="me-2"
              as={Link}
              to={`${url}/send-quotation`}
            >
              <span className="me-3">
                <i className={"fas fa-check"}></i>
              </span>

              {"Accept"}
            </Button>

            <AlertComponent
              removeOne={rejectQRequest}
              nameForAlert={"Request"}
              rejectedRequest={rejectedRequest}
            />
          </div>
        )}
      </Box>
      {withQuotation && HospitalReservationQuotation?.length ? (
        <Box
          bg={checkBgColorMode(colorMode)}
          className=" col-12 p-2 py-4 shadow-sm rounded row m-0 mt-3"
        >
          <Text
            fontSize="xl"
            color={style.secondary}
            fontWeight="semibold"
            className="my-2"
          >
            Offer Details
          </Text>
          {HospitalReservationQuotation.map((offer) => (
            <Box
              key={offer.id}
              className="row align-items-center justify-content-between m-0 my-2"
            >
              <a
                className="col-md-4 d-flex py-2 rounded justify-content-between my-2"
                href={offer.fileLocation}
                target="_blanck"
                style={{ backgroundColor: style.smallTextGrayLight }}
              >
                <Box className="d-flex">
                  <Text className="me-2" color={checkColorMode(colorMode)}>
                    <i className="fas fa-file-pdf"></i>
                  </Text>
                  <Text fontSize="sm">
                    {offer.fileName || "There is no PDF"}
                  </Text>
                </Box>
                <Box className="justify-self-end">
                  <Text className="" color={checkColorMode(colorMode)}>
                    <i className="fas fa-eye"></i>{" "}
                  </Text>
                </Box>
              </a>

              <div className="col-md-2 my-2">
                <Text className="" color={style.smallTextGray} fontSize="sm">
                  The price
                </Text>
                <Text className="fw-bold" fontSize="xs">
                  {offer.price}
                </Text>
              </div>

              <div className="col-md-2 my-2">
                <Text className="" color={style.smallTextGray} fontSize="sm">
                  Earliest check In
                </Text>
                <Text className="fw-bold" fontSize="xs">
                  {moment(offer.earliestCheckInTime * 1000).format(
                    "MM/DD/YYYY - hh:mm A"
                  )}
                </Text>
              </div>

              <div className="col-md-2 my-2">
                <Text className="" color={style.smallTextGray} fontSize="sm">
                  Duration of stay
                </Text>
                <Text className="fw-bold" fontSize="xs">
                  {`${offer.durationInDays} days`}
                </Text>
                <Text></Text>
              </div>
            </Box>
          ))}
        </Box>
      ) : (
        ""
      )}

      {singleInvitation?.patient ? (
        <Box
          as={motion.div}
          className="col-12 mt-3 p-2 py-3 shadow-sm rounded row m-0"
          bg={checkBgColorMode(colorMode)}
        >
          <div className="col-12 px-3">
            <Text fontSize="2xl" color={style.secondary} fontWeight="bold">
              Patient Info
            </Text>
          </div>
          <div className="col-12 row m-0 my-3 justify-content-between">
            <div className="col-md-6 row m-0 p-0 my-2">
              <div className="col-md-6 d-flex align-items-center m-0 p-0">
                <Avatar
                  size="md"
                  src={singleInvitation.patient.picture}
                  name={singleInvitation.patient.fullName}
                  bg={style.primary}
                  color="#fff"
                  className="me-2"
                />

                <Box className="p-0">
                  <Text color="gray.400" fontSize="13px" fontWeight="600">
                    Patient
                  </Text>
                  <Text fontSize="12px" fontWeight="700">
                    {singleInvitation.patient.fullName}
                  </Text>
                </Box>
              </div>
            </div>

            <div className="row col-md-5 text-end p-0">
              <div className="d-flex col-md-6 text-start my-2">
                <Circle className="me-2" size="40px" bg={style.orange}>
                  <i
                    style={{ color: "#FFF" }}
                    className="fas fa-thin fa-face-smile"
                  ></i>
                </Circle>
                <div className="">
                  <Text color="gray.400" fontSize="13px" fontWeight="600">
                    Gender
                  </Text>
                  <Text
                    fontSize="12px"
                    fontWeight="700"
                    textTransform="capitalize"
                  >
                    {singleInvitation.patient.gender}
                  </Text>
                </div>
              </div>
              <div className="d-flex col-md-6 text-start my-2">
                <Circle className="me-2" size="40px" bg={style.green}>
                  <i
                    style={{ color: "#FFF" }}
                    className="fas fa-thin fa-calendar"
                  ></i>
                </Circle>
                <div className="col-8">
                  <Text color="gray.400" fontSize="13px" fontWeight="600">
                    Date of Birth
                  </Text>
                  <Text fontSize="12px" fontWeight="700">
                    {moment(singleInvitation.patient.dateOfBirth * 1000).format(
                      "LL"
                    )}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </Box>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    SingleInvitation: state.SingleInvitation,
    ShareToken: state.ShareToken,
    MedicalFileLink: state.MedicalFileLink,
    rejectedRequest: state.rejectedRequest,
    consultationTypes: state.consultationTypes.data,
  };
};
export default connect(mapStateToProps, {
  getSinglePatientsInvitation,
  generateInformationShareToken,
  getMedicalInformationFileLink,
  rejectQuotationRequest,
})(OverView);
