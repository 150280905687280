import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import FirstView from "../../Repeated-Component/FirstView";
import conditionImage from "../../../../../../../../assets/medical-record/requests/conditions.png";

import { connect } from "react-redux";
import { getConditionList } from "../../../../../../../../store/actions";
import { useToast } from "@chakra-ui/react";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const ListingCondition = ({
  patient,
  getConditionList,
  ConditionList,
  queryParams
}) => {

  const { url } = useRouteMatch();
  let query = useQuery().get("page");
  const toast = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Condition, setCondition] = useState([]);
  const [filterCondition, setFilterCondition] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}${queryParams?"&newRequest=true":""}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}${queryParams?"&newRequest=true":""}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}${queryParams?"&newRequest=true":""}`);
  };

  useEffect(() => {
    if (patient) getConditionList(toast, history, patient.id, query);
  }, [getConditionList, query]);

  useEffect(() => {
    if (ConditionList) {
      const { loading, error, data } = ConditionList;
      setLoading(loading);
      setError(error);
      if (data) {
        setCondition(data.data);
        setFilterCondition(data.data);
        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);
      }
    }
  }, [ConditionList]);
  return (
    <div>
      <FirstView
        loading={loading}
        error={error}
        headerName="Conditions"
        buttonLink={`${url}/request-conditions`}
        cardLink={`${url}/view-conditions`}
        data={Condition}
        filterData={filterCondition}
        setFilter={setFilterCondition}
        conditionImage={conditionImage}
        pages={pagesNumber}
        nextPage={nextPage}
        prevPage={prevPage}
        specificPage={specificPage}
        currentPage={currentPage}
        totalResults={totalResults}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ConditionList: state.ConditionList,
  };
};
export default connect(mapStateToProps, { getConditionList })(ListingCondition);
