import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NotFound from "../../../../../../NotFound/NotFound";

import ListingLabResult from "./Children/listingLabResult";
import ViewOneLabResult from "./Children/viewOneLabResult";
// import RequestLapResult from "./Children/requestLabResult";

const LabResult = ({ patient ,queryParams}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <ListingLabResult patient={patient} queryParams={queryParams}/>}
      />
      {/* <Route
        exact
        path={`${path}/request-lab_result`}
        component={RequestLapResult}
      /> */}
      <Route
        path={`${path}/view-lab_result/:medicalId`}
        render={() => <ViewOneLabResult patient={patient} />}
      />
      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

export default LabResult;
