import React from "react";
import MyInfoChild from "./Children/my_Info/my_info";
import Address from "./Children/address/address";

const MyInfo = ({ hospitalProfile }) => {
  return (
    <div className="ms-2">
      {/* done */}
      <MyInfoChild hospitalProfile={hospitalProfile} />
      {/* done */}
      <Address hospitalProfile={hospitalProfile} />
      {/* done */}
    </div>
  );
};

export default MyInfo;
