import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Avatar, useToast, Box, useColorMode } from "@chakra-ui/react";
import { connect } from "react-redux";

import {
  getHospitalProfile,
  postHospitalProfilePicture,
} from "../../../store/actions";
import { checkColorMode, checkColorModeHover } from "../../global/changeColor";
const UploadProfilePic = ({
  postHospitalProfilePicture,
  profile,
  getHospitalProfile,
  updatedProfilePicture,
}) => {
  const [HospitalProfile, setHospitalProfile] = useState({});
  const toast = useToast();
  const { colorMode } = useColorMode();
  useEffect(() => {
    if (profile) {
      setHospitalProfile(profile.data);
    }
  }, [profile]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      postHospitalProfilePicture(acceptedFiles, toast).then((res) => {
        getHospitalProfile();
      });
    },
  });
  return (
    <Box
      {...getRootProps({
        className: `${
          updatedProfilePicture && updatedProfilePicture.loading
            ? "disabled-pic"
            : ""
        }`,
      })}
    >
      <input {...getInputProps()} />
      <Box className="avatar" position="relative">
        <Avatar
          size="xl"
          name={HospitalProfile ? HospitalProfile.fullName : "..."}
          src={HospitalProfile ? HospitalProfile.picture : ""}
        />
        <Box
          className="d-flex align-items-center justify-content-center text-white rounded-circle"
          position="absolute"
          bottom={0}
          right={0}
          fontSize="12px"
          width="22px"
          height="22px"
          bg={checkColorMode(colorMode)}
          cursor="pointer"
        >
          <span>
            <i className="fas fa-camera"></i>
          </span>
        </Box>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    updatedProfilePicture: state.updateupdatedProfilePicture,
  };
};
export default connect(mapStateToProps, {
  postHospitalProfilePicture,
  getHospitalProfile,
})(UploadProfilePic);
