import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import {
  getSinglePatientsInvitation,
  getConsultationsTyeps,
  getSingleNewRequrst,
} from "../../store/actions";
import OverViewSideNav from "./overViewSideNav";
import OverView from "./OverView";

import { Box, Button, useColorMode } from "@chakra-ui/react";
import AnimatedPages from "../Animation/AnimatedPages";
import OverViewChild from "./overViewChild/overViewChild";
import { checkColorMode } from "../global/changeColor";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const RequestOverView = ({
  requestes,
  medicalReport,
  singleInvitation,
  ConsultationType,
  patient,
  fromNewRequest,
  withQuotation,
  HospitalReservationQuotation,
}) => {
  let { id } = useParams();
  const { path } = useRouteMatch();
  const loaction = useLocation();
  const { colorMode } = useColorMode();
  const [smallSideNav, setSmallSideNav] = useState(false);
  const [overLay, setOverLay] = useState(true);
  let queryParams = useQuery().get("newRequest");
  let history = useHistory();
  const closeSideNave = (someData) => {
    setOverLay(!overLay);
    setSmallSideNav(!smallSideNav);
  };
  return (
    <AnimatedPages>
      <div className="row m-0 position-relative">
        <div className="d-none d-lg-block p-0 m-0 col-2">
          <OverViewSideNav
            requestes={requestes}
            medicalReport={medicalReport}
            queryParams={queryParams ? queryParams : null}
            fromNewRequest={fromNewRequest}
          />
        </div>

        <div className="d-xl-none d-lg-none d-xl-block mt-4">
          <Button
            color={checkColorMode(colorMode)}
            onClick={() => {
              setOverLay(!overLay);
              setSmallSideNav(!smallSideNav);
            }}
          >
            <i className="fas fa-bars"></i>
          </Button>
        </div>

        <Box className="col-lg-10 row flex-wrap m-0 align-items-start main-overView mb-5">
          <AnimatePresence exitBeforeEnter>
            <Switch location={loaction} key={loaction.pathname}>
              <Route
                exact
                path={path}
                render={() => (
                  <OverView
                    singleInvitation={singleInvitation}
                    ConsultationType={ConsultationType}
                    history={history}
                    patient={patient}
                    fromNewRequest={fromNewRequest}
                    withQuotation={withQuotation}
                    HospitalReservationQuotation={HospitalReservationQuotation}
                  />
                )}
              />

              <Route
                path={`${path}/:child`}
                render={() => (
                  <OverViewChild
                    medicalReport={medicalReport}
                    patient={patient}
                    requestes={requestes}
                    history={history}
                    requesrId={id}
                    queryParams={queryParams ? queryParams : null}
                    fromNewRequest={fromNewRequest}
                  />
                )}
              />
            </Switch>
          </AnimatePresence>
        </Box>

        <div
          className="overLay position-absolute "
          style={{
            left: 0,
            right: 0,
            top: 0,
            height: "100%",
            backgroundColor: "rgba(0,0,0,.2)",
            zIndex: "20",
            display: overLay ? "none" : "block",
          }}
          onClick={() => {
            closeSideNave();
          }}
        ></div>
        <div
          className="position-absolute  p-0 m-0 small-side-nave"
          style={{
            top: 0,
            left: smallSideNav ? "0" : "-600px",
            zIndex: "30",
          }}
        >
          <OverViewSideNav
            requestes={requestes}
            medicalReport={medicalReport}
            queryParams={queryParams ? queryParams : null}
            fromNewRequest={fromNewRequest}
            closeSideNave={closeSideNave}
          />
        </div>
      </div>
    </AnimatedPages>
  );
};
const mapStateToProps = (state) => {
  return {
    SingleInvitation: state.SingleInvitation,
    consultationTypes: state.consultationTypes,
    SingleNewRequrst: state.SingleNewRequrst,
  };
};
export default connect(mapStateToProps, {
  getSinglePatientsInvitation,
  getConsultationsTyeps,
  getSingleNewRequrst,
})(RequestOverView);
