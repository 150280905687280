import axios from "axios";
import { types } from "../types/types";

const API_URL = process.env.REACT_APP_API_URL;

const commonTypes = types.commonTypes;

export const getConsultationsTyeps = () => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.GET_CONSULTATION_TYPE_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultation-type/`,
      });
      dispatch({
        type: commonTypes.GET_CONSULTATION_TYPE_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: commonTypes.GET_CONSULTATION_TYPE_ERROR,
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
export const patientTypeList = () => {
  return async (dispatch) => {
    dispatch({
      type: commonTypes.GET_PATIENT_TYPE_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient-type/`,
      });
      dispatch({
        type: commonTypes.GET_PATIENT_TYPE_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: commonTypes.GET_PATIENT_TYPE_ERROR,
        payload: err,
      });
    }
  };
};
