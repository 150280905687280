import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { style } from "../../../../../../global/style";
import moment from "moment";
import { Text, useColorMode, Box } from "@chakra-ui/react";
import { checkBgColorMode } from "../../../../../../global/changeColor";
// https://patient.staging.pat-rec.com/269/shared-data?sharedToken=e35506479991804d3fdaa33057be15da
// https://patient.staging.pat-rec.com/684/shared-data?sharedToken=06786923a149af5117bb487cac075ead
const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -2000, duration: 5, delay: 10 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000, duration: 5, delay: 3 },
    },
  },
};
const Card = ({ url, item, queryParams }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      as={motion.div}
      variants={variants}
      className="mb-2 shadow d-flex align-items-center rounded"
      bg={checkBgColorMode(colorMode)}
    >
      <Link
        to={`${url}/${item.id}${queryParams ? "?newRequest=true" : ""}`}
        className={`${
          colorMode === "dark" ? "text-wite" : "text-dark"
        } w-100 nav-link  p-3`}
      >
        <h5 className="h5 fw-bold mb-0">
          {item.title ? item.title : item.value}
        </h5>
        <Text color={style.smallTextGray} fontSize="13px" className="mt-1">
          {moment
            .unix(item.updated_at || item.updatedAt)
            .format("DD/MM/YYYY hh:mm A")}
        </Text>
      </Link>
    </Box>
  );
};

export default Card;
