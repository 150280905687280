import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import NotFound from "../../../../../../NotFound/NotFound";
import ListingEcg from "./Children/listingEcg";
// import requestEcg from "./Children/requestEcg";
import ViewOneEcg from "./Children/viewOneEcg";

const Ecg = ({patient ,queryParams}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => <ListingEcg patient={patient} queryParams={queryParams}/>}
      />
      {/* <Route exact path={`${path}/request-ecg`} component={requestEcg} /> */}
      <Route
        path={`${path}/view-ecg/:medicalId`}
        render={() => <ViewOneEcg patient={patient} />}
      />
      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

export default Ecg;
