import React, { useEffect, useState } from "react";

import { Button, Text, useToast, Box, useColorMode } from "@chakra-ui/react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateHospitalProfile,
  getHospitalProfile,
} from "../../../../../store/actions";
import { style } from "../../../../global/style";
import { InputControl } from "formik-chakra-ui";
import TelephoneInput from "../../../../global/phoneNumber";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../global/changeColor";

const SubmitSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  phone: Yup.string().min(10).required("Required"),
});

const MyInfoChild = ({
  hospitalProfile,
  updateHospitalProfile,
  getHospitalProfile,
  updatedProfile,
}) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const history = useHistory();
  const [edite, setEdite] = useState(false);
  const [initValue, setInitValue] = useState({
    email: "",
    phone: "",
  });

  const onFormSubmit = (value) => {
    const data = {
      ...hospitalProfile,
      email: value.email,
      phone: value.phone,
      postal_code: hospitalProfile.postalCode,
    };
    updateHospitalProfile(
      data,
      history,
      toast,
      null,
      setEdite,
      getHospitalProfile
    );
  };
  useEffect(() => {
    if (hospitalProfile) {
      setInitValue({
        ...initValue,
        email: hospitalProfile.email,
        phone: hospitalProfile.phone,
      });
    }
  }, [hospitalProfile]);
  const renderView = () => {
    if (edite) {
      return (
        <Formik
          initialValues={initValue}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={SubmitSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                <div className="my-2">
                  <Text
                    fontSize="lg"
                    color={style.secondary}
                    fontWeight="semibold"
                  >
                    {" "}
                    My Info
                  </Text>
                </div>
                <div className="text-end my-2">
                  <Button
                    width={"50px"}
                    height="50px"
                    bg="#f3e9eb"
                    _hover={{ bg: "#f3d3da" }}
                    fontSize="20px"
                    color="#D42C51"
                    className="me-2"
                    onClick={() => setEdite(false)}
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </Button>

                  <Button
                    width={"50px"}
                    height="50px"
                    bg={style.secondary}
                    _hover={{ bg: style.secondary_hover }}
                    fontSize="20px"
                    type="submit"
                    color="#fff"
                    isLoading={updatedProfile?.loading}
                  >
                    <i className="fas fa-save"></i>
                  </Button>
                </div>
              </div>

              <div className="col-12 row m-0">
                <div className="col-md-6 mt-2">
                  <InputControl
                    name="email"
                    label="E-Mail"
                    inputProps={{ placeholder: "E-Mail", disabled: false }}
                  />
                </div>
                <div className="col-md-6  ">
                  <div className="pt-2">
                    <TelephoneInput name="phone" />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-danger errorMsg"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    } else {
      return (
        <>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Text fontSize="lg" color={style.secondary} fontWeight="semibold">
              {" "}
              My Info
            </Text>
            <Button
              width={"50px"}
              height="50px"
              bg={checkColorMode(colorMode)}
              _hover={{ bg: checkColorModeHover(colorMode) }}
              fontSize="17px"
              color={style.white}
              onClick={() => setEdite(true)}
            >
              <i className="fas fa-pen"></i>
            </Button>
          </div>

          <div className="col-12 d-flex flex-wrap">
            <div className="col-md-4 col-12 p-0 mt-3">
              <Text fontWeight="semibold" color={style.smallTextGray}>
                E-Mail
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {hospitalProfile
                  ? `${hospitalProfile.email.substr(0, 25)}`
                  : "null"}
              </Text>
            </div>

            <div className="col-md-4 col-12 p-0 mt-3">
              <Text fontWeight="semibold" color={style.smallTextGray}>
                Phone Number
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                {hospitalProfile ? hospitalProfile.phone : "null"}
              </Text>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="MyInfo rounded shadow-sm p-4  row m-0 flex-wrap mb-4"
    >
      {renderView()}
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    updatedProfile: state.updatedProfile,
  };
};
export default connect(mapStateToProps, {
  getHospitalProfile,
  updateHospitalProfile,
})(MyInfoChild);
