import React from "react";
import UploadProfilePic from "./uploadProfilePic";
import { connect } from "react-redux";
import { Divider, Text, Box, Button, useColorMode } from "@chakra-ui/react";
import { NavLink, useHistory } from "react-router-dom";
import { style } from "../../global/style";
import { checkColorMode } from "../../global/changeColor";
import { logOut } from "../../../store/actions";

const profileLinks = [
  {
    linkTo: "/profile",
    text: "My Info",
    icon: "fa fa-user",
  },
  {
    linkTo: "/profile/change_password",
    text: "Change Password",
    icon: "fa fa-unlock-alt",
  },
];

const ProfileSideNav = ({
  HospitaleProfile,
  logOut,
  openProfileNav,
  setOpenProfileNav,
}) => {
  let history = useHistory();
  let { colorMode } = useColorMode();
  return (
    <>
      {openProfileNav ? (
        <Box className="w-100 text-end my-3">
          <Button
            className=""
            color={checkColorMode(colorMode)}
            onClick={() => {
              setOpenProfileNav(!openProfileNav);
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </Box>
      ) : (
        ""
      )}
      <div className=" d-flex justify-content-center w-100">
        <UploadProfilePic profile={HospitaleProfile} />
      </div>

      <div className="my-3 w-100 text-center">
        <Text fontSize="lg" fontWeight="semibold" color={style.secondary}>
          {HospitaleProfile.data ? HospitaleProfile.data.name : ""}
        </Text>
      </div>

      <div className="my-4 w-100  fw-600 d-flex justify-content-center">
        <Divider className="w-75" />
      </div>

      <div className="list-wrab">
        <ul className="list">
          {profileLinks.map((link, index) => (
            <li
              className={`${
                colorMode === "dark" ? "list-item-dark" : "list-item"
              } mb-2`}
              key={index}
              onClick={() => {
                if (setOpenProfileNav) setOpenProfileNav(!openProfileNav);
              }}
            >
              <NavLink
                exact
                activeClassName={
                  colorMode === "dark"
                    ? "active-profile-link-dark"
                    : "active-profile-link"
                }
                to={link.linkTo}
                className="nav-link rounded w-100 d-flex justify-content-between profile_nav p-2"
              >
                <div className="d-flex">
                  <Text className="me-3">
                    <i className={link.icon}></i>
                  </Text>
                  <Text fontSize="14px" className="me-1">
                    {link.text}
                  </Text>
                </div>
                <Text>
                  <i className="fas fa-chevron-right"></i>
                </Text>
              </NavLink>
            </li>
          ))}
          <li
            className={`${
              colorMode === "dark" ? "list-item-dark" : "list-item"
            } mb-2`}
          >
            <NavLink
              exact
              to="/login"
              className="nav-link rounded w-100 d-flex justify-content-between profile_nav p-2"
              onClick={() => {
                logOut(history);
                if (setOpenProfileNav) setOpenProfileNav(!openProfileNav);
              }}
            >
              <div className="d-flex">
                <Text className="me-3">
                  <i className="fas fa-sign-out-alt"></i>
                </Text>
                <Text fontSize="14px" className="me-1">
                  Logout
                </Text>
              </div>
              <Text>
                <i className="fas fa-chevron-right"></i>
              </Text>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    HospitaleProfile: state.HospitaleProfile,
  };
};
export default connect(mapStateToProps, {
  logOut,
})(ProfileSideNav);
