import React, { useState } from "react";
import AnimatedPages from "../../Animation/AnimatedPages";
import { Form, Formik, ErrorMessage, FieldArray } from "formik";
import {
  Button,
  Text,
  Textarea,
  useToast,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import hospitalivitation from "../../../assets/hospitalivitation.png";
import {
  checkColorMode,
  checkColorModeHover,
  checkBgColorMode,
} from "../../global/changeColor";
import { InputControl } from "formik-chakra-ui";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import InviteModal from "./inviteModal";
import { invitePatient } from "../../../store/actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Schema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .min(3, "must be at least 3 characters")
    .required("Required"),
  lastName: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .min(3, "must be at least 3 characters")
    .required("Required"),
  email: Yup.string().email().required("Email is a required field"),
  invitationText: Yup.string()
    .min(3, "must be at least 3 characters")
    .required("required field"),
});

const AddPatient = ({ invitePatient, InvitedPatient }) => {
  const [addMore, setAddMore] = useState([]);
  const toast = useToast();
  const history = useHistory();
  const { colorMode } = useColorMode();
  const [initVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    invitationText: "",
    requests: [],
  });
  const onFormSubmit = (values) => {
    const data = {
      name: `${values.firstName} ${values.lastName}`,
      email: values.email,
      invitationText: values.invitationText,
      requests: addMore,
    };
    invitePatient(data, toast, history);
  };
  const onAskMoreChange = (value) => {
    if (value) setAddMore([...addMore, value]);
  };
  return (
    <AnimatedPages>
      <div className="container my-5">
        <div className="row m-0">
          <Formik
            enableReinitialize
            initialValues={initVal}
            onSubmit={onFormSubmit}
            validationSchema={Schema}
          >
            {({ values, handleBlur, handleChange }) => (
              <Form className="row m-0">
                <Text fontSize="2xl" fontWeight="semibold" className="mb-3">
                  Add Hospital Requests
                </Text>
                <Box
                  bg={checkBgColorMode(colorMode)}
                  className="row rounded shadow-sm p-3  justify-content-center m-0"
                >
                  <div className="my-5">
                    <div className="d-flex justify-content-center">
                      <img
                        className="image-fluid"
                        src={hospitalivitation}
                        alt="consaltation_image"
                      />
                    </div>
                  </div>

                  <div className="text-center">
                    <Text fontSize="3xl" fontWeight="700">
                      Add Hospital Requests
                    </Text>
                    <Text fontSize="md" fontWeight="400" color="gray.400">
                      Please add info to Add Hospital Requests
                    </Text>
                  </div>

                  <div className="row justify-content-center flex-wrap p-0">
                    <div className=" col-md-4 mt-2 ">
                      <InputControl
                        type="text"
                        name="firstName"
                        className="rounded mt-3"
                        inputProps={{
                          placeholder: "First Name",
                          style: { fontSize: "13.5px" },
                          disabled: false,
                        }}
                      />
                    </div>
                    <div className=" col-md-4 mt-2 ">
                      <InputControl
                        type="text"
                        name="lastName"
                        className="rounded mt-3"
                        inputProps={{
                          placeholder: "Last Name",
                          style: { fontSize: "13.5px" },
                          disabled: false,
                        }}
                      />
                    </div>
                    <div className=" col-md-8 col-12 mt-2 ">
                      <InputControl
                        type="text"
                        name="email"
                        className="rounded mt-3"
                        inputProps={{
                          placeholder: "Email",
                          style: { fontSize: "13.5px" },
                          disabled: false,
                        }}
                      />
                    </div>
                    <div className="col-md-8 col-12 mt-2">
                      <Textarea
                        className="mt-3 rounded"
                        value={values.invitationText}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        name="invitationText"
                        placeholder="Please explain your symptoms in detail."
                        size="sm"
                        fontSize="14px"
                      />
                      <ErrorMessage
                        component="div"
                        name="invitationText"
                        style={{ color: "#d42c51", fontSize: "14px" }}
                        className="mt-1"
                      />
                    </div>
                    <div className="col-md-8 col-12 my-3">
                      <Text fontWeight={700} color="gray.400">
                        Ask More
                      </Text>
                    </div>
                    <div className="col-md-8 ">
                      <FieldArray name="requests ">
                        {({ push, handlePush }) => {
                          return (
                            <div className="row p-0 m-0">
                              <InviteModal
                                feildName="condition"
                                name="Conditions"
                                onAskMoreChange={onAskMoreChange}
                              />
                              <InviteModal
                                feildName="medical_report"
                                name="Medical Report"
                                onAskMoreChange={onAskMoreChange}
                              />
                              <InviteModal
                                feildName="reading"
                                name="Readings"
                                onAskMoreChange={onAskMoreChange}
                              />
                              <InviteModal
                                feildName="ecg"
                                name="ECG"
                                onAskMoreChange={onAskMoreChange}
                              />
                              <InviteModal
                                feildName="allergy"
                                name="Allergies"
                                onAskMoreChange={onAskMoreChange}
                              />
                              <InviteModal
                                feildName="radiology-ct"
                                name="MRI/CT Scan"
                                onAskMoreChange={onAskMoreChange}
                              />
                              <InviteModal
                                feildName="lab_result"
                                name="Lab Result"
                                onAskMoreChange={onAskMoreChange}
                              />
                              <InviteModal
                                feildName="radiology-xray"
                                name="X Ray"
                                onAskMoreChange={onAskMoreChange}
                              />
                            </div>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </Box>

                <div className="row p-0 justify-content-between m-0 my-3">
                  <div className="col-3">
                    <Button
                      colorScheme="red"
                      variant="outline"
                      w="100%"
                      size="lg"
                      fontSize={16}
                    >
                      <Link
                        to="/hospital_requests"
                        className="nav-link text-danger w-100"
                      >
                        Cancel
                      </Link>
                    </Button>
                  </div>
                  <div className="col-3">
                    <Button
                      w="100%"
                      size="lg"
                      type="submit"
                      fontSize={16}
                      bg={checkColorMode(colorMode)}
                      color="#fff"
                      _hover={{ bg: checkColorModeHover(colorMode) }}
                      // isLoading={postedConsultation.loading}
                    >
                      Submit
                      <span className="ms-3 fw-bold">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </AnimatedPages>
  );
};

const mapStateToProps = (state) => {
  return {
    InvitedPatient: state.InvitedPatient,
  };
};
export default connect(mapStateToProps, { invitePatient })(AddPatient);
