import { types } from "../types/types";
const { hospitalTypes } = types;

const INIT_HOSPITALE_STATE = {
  loading: false,
  error: null,
  data: null,
};

export const generateInformationShareToken = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GENERATE_INFORMATION_SHARE_TOKEN_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GENERATE_INFORMATION_SHARE_TOKEN_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GENERATE_INFORMATION_SHARE_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const invitePatient = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.INVITE_PATIENT_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.INVITE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.INVITE_PATIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getPatientsInvitationList = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_INVITATION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_INVITATION_LIST_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_INVITATION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getSinglePatientsInvitation = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_SINGLE_INVITATION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_SINGLE_INVITATION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_SINGLE_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getMedicalInformationFileLink = (
  state = INIT_HOSPITALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case hospitalTypes.GET_MEDICAL_INFORMATION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    case hospitalTypes.GET_MEDICAL_INFORMATION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case hospitalTypes.GET_MEDICAL_INFORMATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
