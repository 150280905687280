import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";

import Header from "./components/header/Header";
import Footer from "./components/Footer/Footer";
import RenderRoute from "./Routes/routes";
import "./App.scss";
import isAuthenticated from "./utils/isAuthenticated";
import { connect } from "react-redux";
import { getHospitalProfile } from "./store/actions";

import theme from "./components/global/Theme";

function App({ getHospitalProfile, hospitaleProfile, Login }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const hospitalId = localStorage.getItem("hospitalId");
  const hospitalToken = localStorage.getItem("hospitalToken");

  useEffect(() => {
    if (isAuthenticated()) {
      setIsLoggedIn(isAuthenticated());
    }
  }, [isAuthenticated()]);
  useEffect(() => {
    if (hospitalId && hospitalToken) {
      getHospitalProfile();
    }
  }, [Login, hospitalId, hospitalToken, getHospitalProfile]);
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <header>
          <Header
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            hospitaleProfile={hospitaleProfile}
          />
        </header>
        <main className="main ">
          <AnimatePresence exitBeforeEnter>
            <RenderRoute />
          </AnimatePresence>
        </main>
        <footer>
          <Footer />
        </footer>
      </ChakraProvider>
    </BrowserRouter>
  );
}
const mapStateToProps = (state) => {
  return {
    hospitaleProfile: state.HospitaleProfile,
    Login: state.login,
  };
};
export default connect(mapStateToProps, { getHospitalProfile })(App);
