import { Box, Button, Image, Text } from "@chakra-ui/react";
import { style } from "./style";
import PDF from "../../assets/svg-icons/pdf.svg";

export const showImageOrPdf = (file) => {
  const fileName = file.fileName;
  const generatedFile = file.generatedFile;
  // const fileLocation = file.fileLocation;
  if (fileName || generatedFile) {
    const startIndex = fileName
      ? fileName.lastIndexOf(".", fileName.length - 1) + 1
      : generatedFile.lastIndexOf(".", generatedFile.length - 1) + 1;
    const checkExt = fileName
      ? fileName.substring(startIndex)
      : generatedFile.substring(startIndex);
    if (checkExt === "pdf") {
      if (file.generatedFile || file.fileLocation) {
        return (
          <Box
            justifyContent="center"
            alignItems="center"
            p="5px"
            display="flex"
            flexWrap="wrap"
            boxSize="170px"
            border={`1px solid ${style.smallTextGrayLightBorder}`}
            className="rounded"
          >
            <Box
              w="100%"
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <Image boxSize="130px" src={PDF} alt={"PDF"} />
            </Box>
            {file.fileName ? (
              <Text
                fontSize="14px"
                fontWeight="semibold"
                color={style.secondary}
              >
                {file.fileName.substring(0, 15) + "..." || "null"}
              </Text>
            ) : null}
          </Box>
        );
      }
    } else if (checkExt === "png" || checkExt === "jpg") {
      return (
        <Image
          src={file.fileLocation}
          alt={file.fileName}
          border={`1px solid ${style.smallTextGrayLightBorder}`}
          className="rounded"
          boxSize="170px"
        />
      );
    } else {
      return null;
    }
  }
};

export const showDownloadBtn = (file) => {
  const fileName = file.fileName;
  const generatedFile = file.generatedFile;
  ////////

  ///////////

  if (fileName || generatedFile) {
    /////
    const startIndex = fileName
      ? fileName.lastIndexOf(".", fileName.length - 1) + 1
      : generatedFile.lastIndexOf(".", generatedFile.length - 1) + 1;
    const checkExt = fileName
      ? fileName.substring(startIndex)
      : generatedFile.substring(startIndex);
    ////////
    if (checkExt === "pdf") {
      return (
        <Button
          className="rounded m-2 text-white"
          bg={style.primary}
          color="#fff"
          fontSize="14px"
          _hover={{ bg: style.primary_hover }}
          minW="140px"
        >
          <a
            href={file.fileLocation || file.generatedFile}
            target="_blank"
            className="text-white"
          >
            <span className="me-3">
              <i className="fas fa-download"></i>
            </span>{" "}
            Download
          </a>
        </Button>
      );
    }
  }
};
