import React, { useState } from "react";
import { connect } from "react-redux";
import EmptyMedicalReport from "../../../../assets/svg-icons/EmptyMedicalReport.svg";

import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import UploadComponent from "./uploadFiles/inputUpload";
import {
  Button,
  Text,
  Box,
  Textarea,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import { style } from "../../../global/style";
import { useHistory, useParams } from "react-router-dom";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { motion } from "framer-motion";
import { sendQuotation } from "../../../../store/actions";
import moment from "moment";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";

const Schema = Yup.object().shape({
  earliestCheckInTime: Yup.string().required("Required").nullable(),
  durationInDays: Yup.string().required("Required"),
  price: Yup.number().required("Required"),
  note: Yup.string(),
  files: Yup.mixed().required(),
});
const AddQuotation = ({ sendQuotation, sendedRequest }) => {
  const { id } = useParams();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const history = useHistory();
  const [File, setFile] = useState({});
  const [initValue] = useState({
    note: "",
    durationInDays: "",
    earliestCheckInTime: null,
    files: null,
    price: "",
  });
  const seeFiles = (file) => {
    if (file) {
      setFile(file[0]);
    }
  };

  const onFormSubmit = (values) => {
    const data = {
      ...values,
      earliestCheckInTime: moment(values.earliestCheckInTime).format("X"),
    };
    sendQuotation(id, data, toast, history, "/");
  };

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      className="row justify-content-center"
    >
      <div className="col-12 d-flex py-4 align-items-center">
        <Button
          onClick={() => history.goBack()}
          className="me-3 bg-light-gray rounded-circle d-flex align-items-center justify-content-center"
          style={{ width: "45px", height: "45px" }}
          color="secondary"
        >
          <i className="fas fa-chevron-left"></i>
        </Button>
        <Text fontSize="2xl" fontWeight="semibold" color="secondary">
          Send Quotation
        </Text>
      </div>

      <div className="form row justify-content-center">
        <Formik
          initialValues={initValue}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={onFormSubmit}
        >
          {({ values, setFieldValue, handleBlur, handleChange }) => (
            <Form>
              <Box
                bg={checkBgColorMode(colorMode)}
                className="col-12 p-4  rounded shadow  justify-content-center"
              >
                <div className="row justify-content-center">
                  <div className="w-100 d-flex justify-content-center mt-5">
                    <img src={EmptyMedicalReport} alt="logo" />
                  </div>

                  <div className="w-100 text-center py-5">
                    <Text fontSize={"2xl"} fontWeight="bold" className="mt-5">
                      Send Quotation
                    </Text>
                    <Text
                      fontSize={"sm"}
                      className="mt-1"
                      color={style.smallTextGray}
                    >
                      Add Quotation Information
                    </Text>
                  </div>
                </div>

                <div className="w-100 row m-0 justify-content-center">
                  <div className=" mt-2 col-10">
                    <InputControl
                      name="price"
                      className="rounded"
                      value={values.price}
                      inputProps={{
                        placeholder: "Price ex: 9.000 GBP",
                        type: "text",
                        disabled: false,
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        let num = /[^0-9.]/g;
                        let test = e.target.value.replace(num, "");
                        setFieldValue("price", test);
                      }}
                    />
                  </div>
                  <div className=" mt-4 col-5">
                    <InputControl
                      name="durationInDays"
                      className="rounded"
                      inputProps={{
                        placeholder: "Duration of stay in days ex: 12",
                        type: "text",
                        disabled: false,
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        let num = /[^0-9.]/g;
                        let test = e.target.value.replace(num, "");
                        setFieldValue("durationInDays", test);
                      }}
                    />
                  </div>
                  <div className=" mt-4 col-5">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        name="earliestCheckInTime"
                        className={`chakra-input ${
                          colorMode === "dark" ? "css-1ipn0iq" : "css-k7r2wc"
                        } mt-1 w-100`}
                        autoOk={true}
                        label=""
                        format="dd/MM/yyyy"
                        views={["year", "month", "date"]}
                        value={values.earliestCheckInTime}
                        onChange={(value) => {
                          setFieldValue("earliestCheckInTime", value);
                        }}
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        inputProps={{
                          placeholder: "Select Date",
                          style: {
                            color: colorMode === "dark" ? "#fff" : "#000",
                          },
                        }}
                        keyboardIcon={
                          <span
                            className={
                              colorMode === "dark"
                                ? "text-white"
                                : "text-secondary"
                            }
                          >
                            <i className="fas fa-calendar-week"></i>
                          </span>
                        }
                        disablePast
                      />
                    </MuiPickersUtilsProvider>

                    <ErrorMessage
                      component={"div"}
                      name="earliestCheckInTime"
                      className={
                        colorMode === "dark"
                          ? "css-rdu9f9 chakra-form__error-message "
                          : "chakra-form__error-message css-170ki1a"
                      }
                    />
                  </div>
                </div>
                <>
                  <div className="w-100 d-flex justify-content-center mt-5 flex-wrap">
                    <Text
                      className="col-10 fw-bold my-2"
                      color="gray.500"
                      fontSize="sm"
                    >
                      Quotation File
                    </Text>
                    <UploadComponent
                      setFieldValue={setFieldValue}
                      name="files"
                      currntFiles={seeFiles}
                    />
                    <ErrorMessage
                      name="files"
                      component="div"
                      className="w-75"
                      style={{ color: "#d42c51", fontSize: "14px" }}
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <Box className="list-group w-75">
                      {File && File.name ? (
                        <Box
                          className="list-group-item my-2 bg-light-gray  rounded   d-flex justify-content-between align-items-center py-1"
                          style={{
                            borderRadius: "10px !important",
                          }}
                          lineHeight="4"
                          border="2px solid gray.300 "
                        >
                          <div className="fs-6 fw-bold d-flex  align-items-center">
                            <Box
                              color={style.primary}
                              fontSize={"20px"}
                              className="me-3"
                            >
                              <i className="fa-solid fa-image"></i>
                            </Box>
                            <div>
                              <Box fontSize={"14px"}>{File.name}</Box>
                              {File.size ? (
                                <span
                                  className="text-secondary"
                                  style={{ fontSize: "11px" }}
                                >
                                  {File.size} Kb
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div>
                            <Button
                              onClick={() => {
                                seeFiles({});
                                values.files = "";
                              }}
                              background="none"
                              border="none"
                              color="red.400"
                              _focus={{
                                border: "none",
                                background: "none",
                              }}
                              _active={{ background: "red.100" }}
                              _hover={{ background: "red.50" }}
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </div>
                        </Box>
                      ) : null}
                    </Box>
                  </div>
                </>
              </Box>

              <div className="col-12 row justify-content-between m-0 mt-3 p-0">
                <div className="col-md-3 p-0">
                  <Button
                    onClick={() => history.goBack()}
                    colorScheme="red"
                    variant="outline"
                    w="100%"
                    size="lg"
                    fontWeight="semibold"
                    h="56px"
                    _hover={{ color: "red" }}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-md-3 p-0">
                  <Button
                    type="submit"
                    className="rounded"
                    fontWeight="semibold"
                    size="lg"
                    isLoading={sendedRequest.loading}
                    bg={checkColorMode(colorMode)}
                    color="#fff"
                    _hover={{ bg: checkColorModeHover(colorMode) }}
                    w="100%"
                    h="56px"
                  >
                    Save{" "}
                    <span className="ms-4">
                      <i className="fas fa-save"></i>
                    </span>
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};
const mapStateToProps = (state) => {
  return {
    sendedRequest: state.sendedRequest,
  };
};
export default connect(mapStateToProps, {
  sendQuotation,
})(AddQuotation);
