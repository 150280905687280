import axios from "axios";
import { types } from "../types/types";
import { handeleError, handeleSuccess } from "./Toast/toast";
const API_URL = process.env.REACT_APP_API_URL;

const authTypes = types.AuthTypes;

export const logIn = (value, history, toast) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.LOGIN_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/hospital/login`,
        data: value,
      });
      if (res) {
        localStorage.clear();
        localStorage.setItem("hospitalToken", res.data.token);
        localStorage.setItem("hospitalId", res.data.id);
        localStorage.setItem("userType", "Hospital");
        handeleSuccess("You’ve successfully logged in", toast);
        history.push("/");
        dispatch({
          type: authTypes.LOGIN_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: authTypes.LOGIN_ERROR,
        payload: err,
      });
    }
  };
};

export const logOut = (history) => {
  return (dispatch) => {
    dispatch({
      type: authTypes.LOGOUT_LOADING,
    });
    try {
      localStorage.clear();
      history.push("/login");
      dispatch({
        type: authTypes.LOGIN_SUCSES,
        payload: "You are logged out sucsessfuly",
      });
    } catch (err) {
      dispatch({
        type: authTypes.LOGOUT_ERROR,
        payload: "Something Went Wrong",
      });
    }
  };
};

export const forgetPassword = (email, setCounter, toast) => {
  return async (dispatch) => {
    dispatch({
      type: authTypes.FORGET_PASSWORD_LOADING,
    });
    try {
      let res = await axios({
        method: "POST",
        url: `${API_URL}/hospital/forgot-password`,
        data: {
          email: email,
        },
      });
      if (res) {
        handeleSuccess("Code sent successfully", toast);
        setCounter(2);
        dispatch({
          type: authTypes.FORGET_PASSWORD_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: authTypes.FORGET_PASSWORD_ERROR,
        payload: err,
      });
    }
  };
};
