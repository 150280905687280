export const navList = [
  {
    text: "Hospital Requests",
    linkTo: "/hospital_requests",
    activeClassName: "header-links-active",
    classNames: "fw-600  header-links",
  },
  {
    text: "Sent Quotations",
    linkTo: "/sent_quotations",
    activeClassName: "header-links-active",
    classNames: "fw-600  header-links",
  },
  {
    text: "Booked Requests",
    linkTo: "/booked_requests",
    activeClassName: "header-links-active",
    classNames: "fw-600  header-links",
  },
];
